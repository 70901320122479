import Script from 'next/script'

export const RecaptchaScript: React.FC = () => (
  <Script
    key="recaptcha"
    id="recaptcha"
    src={`https://www.google.com/recaptcha/enterprise.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_KEY}`}
    strategy="afterInteractive"
  />
)
