export const MODAL_ANIMATION_TIMING = 300
export const modalAnimation = {
  animate: {
    opacity: 1,
    transition: { duration: MODAL_ANIMATION_TIMING / 1000 },
  },
  exit: {
    opacity: 0,
    transition: { duration: MODAL_ANIMATION_TIMING / 1000 },
  },
  initial: {
    opacity: 0,
  },
}

export const fadeInAnimation = {
  animate: {
    opacity: 1,
    transition: { duration: 0.5 },
  },
  exit: {
    opacity: 0,
  },
  initial: {
    opacity: 0,
  },
}

export const bottomUpAnimation = (delayOnAnimate?: number) => ({
  animate: {
    transition: {
      delay: delayOnAnimate,
      duration: 0.4,
    },
    y: 0,
  },
  exit: {
    transition: {
      duration: 0.4,
    },
    y: 200,
  },
  initial: { y: 200 },
})
