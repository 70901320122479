import BaseSvg, { Props } from '../BaseSvg'

const ThumbDownAlt: React.FC<Props> = (props) => (
  <BaseSvg height="12" viewBox="0 0 15 12" width="15" {...props}>
    <path
      d="M9.99984 0H3.99984C3.4465 0 2.97317 0.333333 2.77317 0.813333L0.759837 5.51333C0.699837 5.66667 0.666504 5.82667 0.666504 6V7.33333C0.666504 8.06667 1.2665 8.66667 1.99984 8.66667H6.2065L5.57317 11.7133L5.55317 11.9267C5.55317 12.2 5.6665 12.4533 5.8465 12.6333L6.55317 13.3333L10.9398 8.94C11.1865 8.7 11.3332 8.36667 11.3332 8V1.33333C11.3332 0.6 10.7332 0 9.99984 0ZM9.99984 8L7.1065 10.8933L7.8465 7.33333H1.99984V6L3.99984 1.33333H9.99984V8ZM12.6665 0H15.3332V8H12.6665V0Z"
      fill="currentColor"
    />
  </BaseSvg>
)

export default ThumbDownAlt
