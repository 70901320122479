import BaseSvg, { Props } from '../BaseSvg'

const MyLocation: React.FC<Props> = (props) => (
  <BaseSvg {...props}>
    <g clipPath="url(#clip0_14003_21904)">
      <path d="M8.6263 5.83317C7.15297 5.83317 5.95964 7.0265 5.95964 8.49984C5.95964 9.97317 7.15297 11.1665 8.6263 11.1665C10.0996 11.1665 11.293 9.97317 11.293 8.49984C11.293 7.0265 10.0996 5.83317 8.6263 5.83317ZM14.5863 7.83317C14.2796 5.05317 12.073 2.8465 9.29297 2.53984V1.1665H7.95963V2.53984C5.17964 2.8465 2.97297 5.05317 2.6663 7.83317H1.29297V9.1665H2.6663C2.97297 11.9465 5.17964 14.1532 7.95963 14.4598V15.8332H9.29297V14.4598C12.073 14.1532 14.2796 11.9465 14.5863 9.1665H15.9596V7.83317H14.5863ZM8.6263 13.1665C6.0463 13.1665 3.95964 11.0798 3.95964 8.49984C3.95964 5.91984 6.0463 3.83317 8.6263 3.83317C11.2063 3.83317 13.293 5.91984 13.293 8.49984C13.293 11.0798 11.2063 13.1665 8.6263 13.1665Z" />
    </g>
    <defs>
      <clipPath id="clip0_14003_21904">
        <rect height="16" transform="translate(0.625 0.5)" width="16" />
      </clipPath>
    </defs>
  </BaseSvg>
)

export default MyLocation
