import React, { useContext, useMemo, PropsWithChildren, useEffect, useState, useCallback } from 'react'

import { usePageFocus } from '@/hooks/usePageFocus'
import { User } from '@/types/authentication'
import { createAuthClient, AuthCredentials, EnabledSocialLoginProvider } from '@/utils/auth/auth-client'

import { useSuggestedCriteria } from '../SuggestedCriteriaProvider'

import { LogoutActionType, OnLogoutAction, logoutOnPreviousPage } from './Logout'

type AuthContextType = {
  isLoading: boolean
  loginWithCredentials: (data: AuthCredentials) => Promise<void>
  loginWithProvider: (provider: EnabledSocialLoginProvider, returnUrl: string) => Promise<void>
  logout: (redirectUrl: OnLogoutAction) => Promise<void>
  recoverPassword: {
    recoveringEmail: () => string | null
    start: (email: string) => Promise<void>
    update: (newPassword: string) => Promise<void>
    verify: (code: string) => Promise<void>
  }
  registerUser: (data: AuthCredentials) => Promise<void>
  user: User | null
}

export const AuthContext = React.createContext<AuthContextType>({
  isLoading: true,
  loginWithCredentials: null,
  loginWithProvider: null,
  logout: null,
  recoverPassword: {
    recoveringEmail: null,
    start: null,
    update: null,
    verify: null,
  },
  registerUser: null,
  user: null,
})

export const useAuthentication = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error(`useAuthentication must be used within a AuthProvider`)
  }
  return context
}

export const pushUserIntoDataLayer = (user: User) => {
  if (typeof window.dataLayer !== 'undefined') {
    window.dataLayer.push({
      event: 'casavo.user.id',
      userspaceId: user?.casavo_user_id,
    })
  }
}

export const removeUserFromDataLayer = () => {
  if (typeof window.dataLayer !== 'undefined') {
    window.dataLayer.push({
      event: 'casavo.user.id',
      userspaceId: undefined,
    })
  }
}

export const AuthProvider: React.FC<PropsWithChildren<React.ReactNode>> = ({ children }) => {
  const authClient = useMemo(() => createAuthClient(process.env.NEXT_PUBLIC_KRATOS_ENDPOINT), [])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [user, setUser] = useState<User | null>(null)
  const { disableSuggestedCriteria } = useSuggestedCriteria()

  const fetchCurrentUser = useCallback(() => {
    authClient
      .fetchCurrentUser()
      .then((user) => {
        pushUserIntoDataLayer(user)
        setUser(user)
      })
      .catch((error) => {
        if (error && (error.message as string).includes('401')) {
          setUser(null)
        }
      })
      .finally(() => setIsLoading(false))
  }, [user])

  useEffect(() => {
    fetchCurrentUser()
  }, [])

  usePageFocus(fetchCurrentUser)

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        loginWithCredentials: (data) =>
          authClient.loginWithEmailAndPassword(data).then((user) => {
            setUser(user)
            pushUserIntoDataLayer(user)
          }),
        loginWithProvider: authClient.loginWithProvider,
        logout: (logoutAction) =>
          authClient.logout().then(() => {
            removeUserFromDataLayer()

            switch (logoutAction.type) {
              case LogoutActionType.PREVIUOS_PAGE:
                return logoutOnPreviousPage(logoutAction.previousPage)
              case LogoutActionType.RELOAD_PAGE:
                window.location.reload()
                break
              case LogoutActionType.SAME_PAGE:
                setUser(null)
                disableSuggestedCriteria()
                break
            }
          }),
        recoverPassword: {
          recoveringEmail: () => {
            return authClient.getRecoveringEmail()
          },
          start: async (email: string) => {
            await authClient.logout().catch(() => {})
            await authClient.recoverPasswordStart(email)
          },
          update: async (newPassword: string) => {
            await authClient.recoverPasswordUpdate(newPassword)
            await authClient.logout()
          },
          verify: authClient.recoverPasswordVerify,
        },
        registerUser: async (data) => {
          await authClient.logout().catch(() => {})
          await authClient.registerUser(data).then((user) => {
            pushUserIntoDataLayer(user)
            setUser(user)
          })
        },
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
