// eslint-disable-next-line no-undef

module.exports = {
  defaultLocale: 'en',
  // loadLocaleFrom: (lang, ns) => import(`./src/locales/${lang}/${ns}.json`).then((m) => m.default),
  loadLocaleFrom: (lang, ns) => {
    if (ns === 'zone-text') {
      return require(`./src/locales-hardcoded/${lang}/zone-text.json`)
    }
    return require(`./src/locales/${lang}/${ns}.json`)
  },
  locales: ['it', 'en', 'fr'],
  logBuild: false,
  pages: {
    '*': ['common', 'header-menu', 'auth-area', 'privacy', 'action-bar'],
    '/[home]': ['houses-for-sale', 'zones', 'casavo-properties-banner'],
    '/[home]/[city]': [
      'filter',
      'zones',
      'save-search',
      'canonical-filters',
      'favourites',
      'contact-form',
      'buyer-interest-flow',
      'priority',
      'suggested',
    ],
    '/[home]/[city]/[...params]': [
      'filter',
      'zones',
      'save-search',
      'listing-detail',
      'furniture',
      'contact-form',
      'casavo-properties-banner',
      'canonical-filters',
      'zone-text',
      'favourites',
      'PropertyAdvantages',
      'buyer-interest-flow',
      'affordability',
      'priority',
      'suggested',
    ],
    '/[home]/annonces/[uuid]': [
      'filter',
      'zones',
      'save-search',
      'listing-detail',
      'furniture',
      'contact-form',
      'casavo-properties-banner',
      'canonical-filters',
      'zone-text',
      'favourites',
      'PropertyAdvantages',
      'buyer-interest-flow',
      'affordability',
      'suggested',
      'priority',
    ],
    '/[home]/annunci/[uuid]': [
      'filter',
      'zones',
      'save-search',
      'listing-detail',
      'furniture',
      'contact-form',
      'casavo-properties-banner',
      'canonical-filters',
      'zone-text',
      'favourites',
      'PropertyAdvantages',
      'buyer-interest-flow',
      'affordability',
      'suggested',
      'priority',
    ],
    '/[home]/property/[uuid]': [
      'filter',
      'zones',
      'save-search',
      'listing-detail',
      'furniture',
      'contact-form',
      'casavo-properties-banner',
      'canonical-filters',
      'zone-text',
      'favourites',
      'PropertyAdvantages',
      'buyer-interest-flow',
      'affordability',
      'suggested',
      'priority',
    ],
    '/affordability': ['affordability', 'contact-form'],
    '/almost-there': ['suggested', 'common', 'auth-area'],
    '/i-wish/[city]': ['buyer-interest-flow', 'contact-form', 'zones'],
    '/listings/[uuid]': ['listing-detail'],
    '/priority': ['priority'],
    '/priority/[slug]': [
      'priority',
      'listing-detail',
      'PropertyAdvantages',
      'auth-area',
      'contact-form',
      'suggested',
      'unicredit',
    ],
    '/priority/[slug]/thankyou': ['priority'],
    '/profile/favorites': ['favourites', 'common'],
    '/profile/suggested': ['suggested', 'common', 'zones', 'main-search', 'priority'],
    '/profile/suggested/settings': ['buyer-interest-flow', 'suggested', 'common', 'zones', 'main-search'],
    '/suggested': ['suggested'],
    '/suggested/onboarding': ['suggested', 'common', 'main-search'],
    '/suggested/onboarding/[city]': ['buyer-interest-flow', 'zones', 'suggested'],

    '/unsubscribe': ['unsubscribe', 'common', 'priority'],
  },
}
