import { SvgProps } from '..'
import BaseSvg from '../BaseSvg'

const ArrowUp: React.FC<SvgProps> = (props) => {
  return (
    <BaseSvg {...props}>
      <path d="M8.855 6.687l-5.456 5.332-1.242-1.271 6.698-6.546 6.988 6.829-1.242 1.271z" />
    </BaseSvg>
  )
}

export default ArrowUp
