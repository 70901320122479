import { capitalize } from 'lodash'

export const isValidForTracking = (value: unknown): boolean =>
  (typeof value !== 'string' || value !== '') && value !== undefined && value !== null

export const getPage = (): string => document.location.pathname + document.location.search

export const getLocation = (): string =>
  document.location.protocol + '//' + document.location.hostname + document.location.pathname + document.location.search

export const dataLayerPush = (params: Record<string, unknown>): void => {
  if (typeof window.dataLayer !== 'undefined') {
    window.dataLayer.push({
      location: getLocation(),
      page: getPage(),
      ...params,
    })
  }
}

export const mapFilterLabelToGtmLabel = (filterLabel: string): string => {
  switch (filterLabel) {
    case 'entrance_floor':
      return 'Floor'
    case 'state_of_manteinance':
      return 'PropertyCondition'
    case 'state_at_deed':
      return 'PropertyStatus'
    case 'heating_system':
      return 'HeatingSystem'
    case 'services':
      return 'Amenities'
    case 'year_of_construction':
      return 'ConstructionYear'
    default:
      return capitalize(filterLabel)
  }
}
