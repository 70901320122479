import { SvgProps } from '..'
import BaseSvg from '../BaseSvg'

const Plus: React.FC<SvgProps> = (props) => {
  return (
    <BaseSvg {...props}>
      <path
        clipRule="evenodd"
        d="M8.83594 1.33594V7.16927H14.6693V8.83594H8.83594V14.6693H7.16927V8.83594H1.33594V7.16927H7.16927V1.33594H8.83594Z"
        fill="#334454"
        fillRule="evenodd"
      />
    </BaseSvg>
  )
}

export default Plus
