import { SvgProps } from '..'
import BaseSvg from '../BaseSvg'

const Rooms: React.FC<SvgProps> = (props) => {
  return (
    <BaseSvg {...props}>
      <path d="M15 14h1v2H0v-2h1V2l7-2 7 2v12zM3 14h4v-2H3v2zm10-2H9v2h4v-2zm0-2V7H9v3h4zM3 10h4V7H3v3zm5-7.92L3 3.508V5h10V3.509L8 2.08z" />
    </BaseSvg>
  )
}

export default Rooms
