import { SvgProps } from '..'
import BaseSvg from '../BaseSvg'

const Bathroom: React.FC<SvgProps> = (props) => {
  return (
    <BaseSvg {...props}>
      <path d="M12 0c2.21 0 4 1.79 4 4v7.5c0 1.396-.817 2.6-1.999 3.163L14 15c0 .552-.448 1-1 1s-1-.448-1-1H4c0 .552-.448 1-1 1s-1-.448-1-1v-.337c-1.182-.562-2-1.767-2-3.163V9c0-.552.448-1 1-1h13V4c0-1.054-.816-1.918-1.85-1.995L12 2h-1.535c.34.588.535 1.271.535 2v2H3V4c0-2.21 1.79-4 4-4h5zm2 10H2v1.5c0 .78.595 1.42 1.356 1.493L3.5 13h9c.78 0 1.42-.595 1.493-1.356L14 11.5V10zM7 2c-1.054 0-1.918.816-1.995 1.85L5 4h4c0-1.054-.816-1.918-1.85-1.995L7 2z" />
    </BaseSvg>
  )
}

export default Bathroom
