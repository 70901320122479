import { dataLayerPush } from './tracking/trackingUtils'

export type EventAction =
  | 'Click'
  | 'Submit'
  | 'FieldUpdate'
  | 'System'
  | 'Drag'
  | 'ScrollUp'
  | 'Collapse'
  | 'Show'
  | 'Expand'

type PropsEventInteraction = {
  eventAction: EventAction
  eventCategory: string
  eventLabel: string
  eventValue?: string
}

export const sendEventInteraction = ({
  eventAction,
  eventCategory,
  eventLabel,
  eventValue,
}: PropsEventInteraction): void => {
  if (typeof window.dataLayer !== 'undefined') {
    dataLayerPush({
      event: 'casavo.user.interactions',
      eventAction,
      eventCategory,
      eventLabel,
      eventType: 'generic',
      eventValue,
    })
  }
}
