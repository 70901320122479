import { SvgProps } from '..'
import BaseSvg from '../BaseSvg'

const Delete: React.FC<SvgProps> = (props) => {
  return (
    <BaseSvg height="20" viewBox="0 0 18 20" width="18" {...props}>
      <path
        d="M12.4475 1.35731C12.5041 1.47637 12.5357 1.60957 12.5357 1.75016V3.19064L17.25 3.19064V5.54778H0.75V3.19064L5.46429 3.19064V1.75016C5.46429 1.60957 5.49594 1.47637 5.5525 1.35731C5.69962 1.04761 6.01528 0.833496 6.38095 0.833496H11.619C11.9847 0.833496 12.3004 1.04761 12.4475 1.35731Z"
        fill="currentColor"
      />
      <path
        d="M2.84524 6.72635V16.6787C2.84524 17.185 3.25564 17.5954 3.76191 17.5954H14.2381C14.7444 17.5954 15.1548 17.185 15.1548 16.6787V6.72635H16.9881V16.6787C16.9881 18.1975 15.7569 19.4287 14.2381 19.4287H3.76191C2.24312 19.4287 1.0119 18.1975 1.0119 16.6787V6.72635H2.84524Z"
        fill="currentColor"
      />
      <path d="M5.46429 6.72635H7.82143V14.9764H5.46429V6.72635Z" fill="currentColor" />
      <path d="M10.1786 6.72635H12.5357V14.9764H10.1786V6.72635Z" fill="currentColor" />
    </BaseSvg>
  )
}

export default Delete
