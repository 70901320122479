import { SvgProps } from '..'
import BaseSvg from '../BaseSvg'

const Minus: React.FC<SvgProps> = (props) => {
  return (
    <BaseSvg {...props}>
      <path
        clipRule="evenodd"
        d="M3.33594 7.33594H12.6693V8.66927H3.33594V7.33594Z"
        fill="#334454"
        fillRule="evenodd"
      />
    </BaseSvg>
  )
}

export default Minus
