import React, { createContext, useContext, useState, useEffect } from 'react'

type ContextValue = {
  isKeydown: boolean
}
export const KeydownContext = createContext<ContextValue>({ isKeydown: false })

export const useKeydown = () => {
  const context = useContext(KeydownContext)

  if (!context) {
    throw new Error(`useKeydown must be used within a KeydownContextProvider`)
  }

  return context
}

export const KeydownContextProvider: React.FC = ({ children }) => {
  const [state, setState] = useState(false)

  const handleMouseDown = () => {
    setState(false)
  }

  const handleKeyDown = () => {
    setState(true)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown)
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('mousedown', handleMouseDown)
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return <KeydownContext.Provider value={{ isKeydown: state }}>{children}</KeydownContext.Provider>
}
