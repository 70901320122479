import { countBy } from 'lodash'

import { CateCity } from '@/context/Cities'

const getCitiesWithoutProvince = (cities: CateCity[]): string[] => {
  const areasWithoutHinterland: string[] = hinterlandSizeByArea(cities)
    .filter(byCitiesWithoutHinterland)
    .map(getReferenceArea)

  return getCityNamesWithoutHinterland(cities, areasWithoutHinterland)
}

/** Returns whether a given city supports having a "-province" page
 *
 * @param cityName The name of the city (case-insensitive)
 * @param cities List of all the cities
 */
const citySupportsProvince = (cityName: string, cities: CateCity[]) => {
  const list = getCitiesWithoutProvince(cities)
  return !list.includes(cityName.toUpperCase())
}

type CitiesCounterForReferenceArea = [string, number]

/**
 * filters out the reference areas that are pointed to by other cities beside themselves
 * @param referenceAreaAndCount a tuple composed by a referenceArea slug and the number of cities that point to it */
const byCitiesWithoutHinterland = (referenceAreaAndCount: CitiesCounterForReferenceArea): boolean =>
  referenceAreaAndCount[1] === 1

const getReferenceArea = (counter: CitiesCounterForReferenceArea): string => counter[0]

const hinterlandSizeByArea = (cities: CateCity[]) => Object.entries(countBy(cities, (city) => city.referenceArea))

const getCityNamesWithoutHinterland = (cities: CateCity[], areasWithoutHinterland) =>
  cities
    .filter((city: CateCity) => areasWithoutHinterland.includes(city.slug))
    .map((city: CateCity) => city.name.toUpperCase())

const utils = {
  citySupportsProvince,
  getCitiesWithoutProvince,
}

export default utils
