import fetch from 'isomorphic-unfetch'
import { v4 as uuidv4 } from 'uuid'

import { FavouriteSearchOrigin } from '@/lib/api-gluglu-open'
import { UserValuation } from '@/pages/profile/suggested'
import { Listing } from '@/types/graphql-generated-types'
import { VisitTimeSlot } from '@/types/visit'

export type FavoriteSearchPayload = {
  email: string
  origin: FavouriteSearchOrigin
  searchCriteria: {
    area?: {
      from: number | null
      to: number | null
    }
    bathrooms?: {
      from: number | null
      to: number | null
    }
    bedrooms?: {
      from: number | null
      to: number | null
    }
    constructionYear?: {
      from: number | null
      to: number | null
    }
    floor?: Array<string>
    geoSlugs: string[]
    heatingSystem?: Array<string>
    mustHaveAmenities?: {
      balcony?: boolean
      basement?: boolean
      box?: boolean
      conciergeService?: boolean
      coolingSystem?: boolean
      elevator?: boolean
      garden?: boolean
      parking?: boolean
      pool?: boolean
      terrace?: boolean
    }
    onlyCasavo: boolean
    price?: {
      from: number | null
      to: number | null
    }
    referenceCitySlug: string
    residentialType?: Array<string>
    rooms?: {
      from: number | null
      to: number | null
    }
    stateAtDeed?: Array<string>
    stateOfMaintenance?: Array<string>
  }
}

export type BookVisitPayload = {
  consentForCasavoMortgageService: boolean
  currentLanguage: Locale
  keepMeUpdated: boolean
  name: string
  phoneNumber: string
  trackingClientId: string | null
  visitSlot: VisitTimeSlot
}

export type ScheduleCallPayload = {
  call_slot: VisitTimeSlot
  consent_for_service_mortgage: boolean
  consent_for_updates: boolean
  current_language: string
  full_name: string
  listing_info: {
    casavo_direct_sale_property: boolean
    city: string
    listing_number_of_rooms: number
    listing_selling_price: number
    listing_title: string
    listing_url: string
    listing_zone: string
    selling_unit_id: string
    transaction_id: string
  }
  phone_number: string
  tracking_client_id: string | null
}

export const API_BASE_URL: string = process.env.NEXT_PUBLIC_GLUGLU_PRIVATE_API_ENDPOINT!

type Headers = Record<string, string>

const getHeaders = (): Headers => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Request-ID': uuidv4(),
})

export const httpRequest =
  (customHeaders?: Headers) => async (url: string, method: RequestInit['method'], payload?: unknown) => {
    const mergedHeaders = { ...getHeaders(), ...customHeaders }
    return fetch(url, {
      body: JSON.stringify(payload),
      credentials: 'include',
      headers: mergedHeaders,
      method,
    })
  }

const defaultHttpRequest = httpRequest()
type HttpRequestFunction = typeof defaultHttpRequest

export const httpQueryFunction = async <T>({ queryKey }: { queryKey: string[] }): Promise<T | null> => {
  return fetch(queryKey[0], {
    credentials: 'include',
    headers: getHeaders(),
    method: 'GET',
  }).then((res) => {
    if (!res.ok) {
      if (res.status === 404) return
      throw new Error()
    }
    return res.json()
  })
}

export enum GetPotentialPropertyVisitInterestResponse {
  INTEREST_ALREADY_EXPRESSED = 'INTEREST_ALREADY_EXPRESSED',
  INTEREST_NOT_EXPRESSED = 'INTEREST_NOT_EXPRESSED',
}

export type GluGluApiClientPrivate = {
  addFavorite: (listingId: Listing['id']) => Promise<void>
  addSuggestionCriteria: (payload: SuggestedCriteria) => Promise<Response>
  allFavorites: () => Promise<FavoriteListing[]>
  bookVisit: (listingId: Listing['id'], payload: BookVisitPayload) => Promise<void>
  getPotentialPropertyVisitInterest: (transactionId: string) => Promise<GetPotentialPropertyVisitInterestResponse>
  getSuggestionCriteria: () => Promise<SuggestedCriteria>
  getSuggestionCriteriaURL: string
  isFavorite: (listingId: Listing['id']) => Promise<boolean>
  performStoreUserValuation: (payload: PropertyValuationPayload) => Promise<{ valuation_id: string }>
  potentialPropertyVisitInterest: (transactionId: string, payload: PotentialPropertyVisitInterest) => Promise<void>
  removeFavorite: (listingId: Listing['id']) => Promise<void>
  requestInfoCasavoListing: (listingId: string, payload: RequestInfoPayload) => Promise<Response>
  scheduleCall: (listingId: Listing['id'], payload: ScheduleCallPayload) => Promise<void>
  submitUserFavoriteSearch: (payload: Omit<FavoriteSearchPayload, 'email'>) => Promise<void>
  updateSuggestedCriteria: (payload: SuggestedCriteria) => Promise<void>
  valuationForValuationId: (valuationId: string) => Promise<UserValuation>
}

/**
 * @field budget in EUR (i.e. 125_000 for 125.000,00€)
 */
export type SuggestedCriteria = {
  budget: number
  geo_slugs: string[]
  origin?: 'INFERRED' | 'USER_PROVIDED'
  rooms_min: number
}

export type FavoriteListing = {
  added_at: string
  listing_id: string
}

export type RequestInfoPayload = {
  consent_for_service_mortgage: boolean
  consent_for_updates: boolean
  current_language: 'IT' | 'EN' | 'ES' | 'PT'
  name: string
  phone_number: string
  reason: 'VISIT' | 'MORE_PHOTOS' | 'MORE_ABOUT_HOUSE' | 'OTHER'
  tracking_client_id: string
}

export type PotentialPropertyVisitInterest = {
  name: string
  phone: string
}

export type PropertyValuationPayload = {
  ad_link?: string
  brand_discovery_source?:
    | 'PERSONAL_RECOMMENDATION'
    | 'ONLINE_ADVERTISING'
    | 'SOCIAL_MEDIA'
    | 'SEARCH'
    | 'TV'
    | 'RADIO_PODCAST_SPOTIFY'
    | 'NEWSPAPER'
    | 'BILLBOARD'
    | 'EVENT'
  google_client_id?: string
  property: {
    address: {
      city: string
      number: string
      street: string
      zip_code: string
    }
    balconies?: number | null
    bathrooms: number
    box_areas?: number[] | null
    building_floors: number
    commercial_area: number
    construction_year: number
    coordinates: {
      lat?: number
      lon?: number
    }
    energy_rating: 'A' | 'APLUS' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'PENDING_REQUEST' | 'NONE'
    entrance_floor: number
    floors: {
      building_floor: number
      entire_floor: boolean
      shift: string
    }[]
    garden_area?: number | null
    has_elevator: boolean
    has_pool: boolean
    heating_system: 'AUTONOMOUS' | 'NONE' | 'SHARED'
    residential_type:
      | 'APARTMENT'
      | 'ATTIC'
      | 'HOUSE'
      | 'LOFT'
      | 'MANSARD'
      | 'TOWNHOUSE'
      | 'VILLA'
      | 'DUPLEX'
      | 'STUDIO'
      | 'COTTAGE'
      | 'OTHER'
    rooms: number
    state_at_deed: 'BARE_OWNERSHIP' | 'FREE' | 'OCCUPIED' | 'RENTED'
    state_of_maintenance: 'HABITABLE' | 'NEED_RENOVATION' | 'NEW' | 'RENOVATED' | 'UNDER_CONSTRUCTION'
    terrace_areas?: number[] | null
    view_description?: 'BOTH' | 'EXTERNAL' | 'INTERNAL'
  }
  valuation_reason_survey: {
    also_interested_in_selling?: boolean
    reason: 'INTERESTED_IN_BUYING'
    reason_not_to_sell?: 'JUST_BOUGHT_THIS_PROPERTY' | 'JUST_SOLD_THIS_PROPERTY' | 'LONG_TERM_RENT' | 'NOT_PLANNED_YET'
    selling_process_status?: 'NOT_STARTED' | 'PUBLISHED_ON_BROKER_LISTING' | 'DIRECT_SELLING' | 'STALED'
  }
  valuation_session_id?: string | null
}

export const initApi = (
  baseURL: string = API_BASE_URL,
  httpRequestFn: HttpRequestFunction = httpRequest()
): GluGluApiClientPrivate => ({
  async addFavorite(listingId) {
    try {
      const response = await httpRequestFn(`${baseURL}/listing/${listingId}/favorite`, 'POST')
      if (!response.ok) {
        throw Error(`Error while sending the request. Status code ${response.status}`)
      }
    } catch (e) {
      console.error(e.message)
      throw e
    }
  },
  async addSuggestionCriteria(payload) {
    try {
      const response = await httpRequestFn(`${baseURL}/user/suggestion-criteria`, 'POST', payload)
      if (response.ok || response.status === 409) {
        return response
      }
      throw Error(`Error while sending the request. Status code ${response.status}`)
    } catch (e) {
      console.error(e.message)
      throw e
    }
  },
  async allFavorites(): Promise<FavoriteListing[]> {
    try {
      const resp = await httpRequestFn(`${baseURL}/user/favorite-listings`, 'GET')
      if (!resp.ok) {
        throw new Error(resp.statusText)
      }
      return await resp.json()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message)
      throw e
    }
  },
  async bookVisit(listingId, payload) {
    try {
      const response = await httpRequestFn(`${baseURL}/user/listing/${listingId}/book-visit`, 'POST', payload)
      if (!response.ok) {
        const data = await response.json()

        throw Error(
          data.reason === 'INVALID_EMAIL' ? data.reason : 'Error while sending request visit. Service returned 500.'
        )
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message)
      throw e.message
    }
  },
  async getPotentialPropertyVisitInterest(transactionId: string) {
    try {
      const response = await httpRequestFn(
        `${baseURL}/user/potential-properties/${transactionId}/express-interest-in-visit`,
        'GET'
      )
      if (response.status === 204) {
        return GetPotentialPropertyVisitInterestResponse.INTEREST_ALREADY_EXPRESSED
      }
      if (response.status === 404) {
        return GetPotentialPropertyVisitInterestResponse.INTEREST_NOT_EXPRESSED
      }
      throw Error(`Error during getting visit interest ${response.status} ${response.statusText}`)
    } catch (e) {
      console.error(e.message)
      throw e.message
    }
  },
  async getSuggestionCriteria() {
    try {
      const response = await httpRequestFn(`${baseURL}/user/suggestion-criteria`, 'GET')
      if (!response.ok) {
        if (response.status === 404) {
          return
        }
        throw Error(`Error while sending the request. Status code ${response.status}`)
      }
      return response.json()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message)
      throw e
    }
  },
  getSuggestionCriteriaURL: `${baseURL}/user/suggestion-criteria`,
  async isFavorite(listingId) {
    try {
      const response = await httpRequestFn(`${baseURL}/listing/${listingId}/favorite`, 'GET')
      return response.ok
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message)
      throw e
    }
  },
  async performStoreUserValuation(payload) {
    try {
      const response = await httpRequestFn(`${baseURL}/user/valuations`, 'POST', payload)
      if (!response.ok) {
        throw Error(`Error while sending the request. Status code ${response.status}`)
      }
      return response.json()
    } catch (e) {
      console.error(e.message)
      throw e
    }
  },
  potentialPropertyVisitInterest: async (transactionId: string, payload: PotentialPropertyVisitInterest) => {
    try {
      const response = await httpRequestFn(
        `${baseURL}/user/potential-properties/${transactionId}/express-interest-in-visit`,
        'POST',
        payload
      )
      if (!response.ok) {
        throw Error(`Error during sending visit interest ${response.status} ${response.statusText}`)
      }
    } catch (e) {
      console.error(e.message)
      throw e.message
    }
  },
  async removeFavorite(listingId) {
    try {
      const response = await httpRequestFn(`${baseURL}/listing/${listingId}/favorite`, 'DELETE')
      if (!response.ok) {
        throw Error(`Error while sending the request. Status code ${response.status}`)
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message)
      throw e
    }
  },
  async requestInfoCasavoListing(listingId, payload) {
    try {
      const response = await httpRequestFn(`${baseURL}/user/listing/${listingId}/request-info`, 'POST', payload)
      if (response.ok) {
        return response
      }
      throw Error(`Error while sending the request. Status code ${response.status}`)
    } catch (e) {
      console.log(e.message)
      throw e
    }
  },
  async scheduleCall(listingId, payload) {
    try {
      const response = await httpRequestFn(`${baseURL}/user/listing/${listingId}/schedule-call`, 'POST', payload)
      if (!response.ok) {
        const data = await response.json()

        throw Error(
          data.reason === 'INVALID_EMAIL' ? data.reason : 'Error while sending request visit. Service returned 500.'
        )
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message)
      throw e.message
    }
  },
  async submitUserFavoriteSearch(payload) {
    const response = await httpRequestFn(`${baseURL}/user/favorite-searches`, 'POST', payload)
    if (!response.ok) {
      const data = await response.json()
      if (data.reason === 'INVALID_EMAIL') {
        throw Error(data.reason)
      }
      throw new Error(response.status + ' response on favorite search submit')
    }
  },
  async updateSuggestedCriteria(payload) {
    try {
      const response = await httpRequestFn(`${baseURL}/user/suggestion-criteria`, 'PUT', payload)
      if (!response.ok) {
        throw Error(`Error while sending the request. Status code ${response.status}`)
      }
    } catch (e) {
      console.error(e.message)
      throw e
    }
  },
  async valuationForValuationId(valuationId) {
    try {
      const response = await httpRequestFn(`${baseURL}/user/valuations/${valuationId}`, 'GET')
      if (!response.ok) {
        throw Error(`Error while sending the request. Status code ${response.status}`)
      }
      return response.json()
    } catch (e) {
      console.error(e.message)
      throw e
    }
  },
})

export default initApi()
