export const TENANT_DOMAIN_ENVIRONMENT_MAPPING = {
  unicreditProduction: 'unicreditres',
  unicreditStaging: 'lisa-ucsc',
}
export const TENANTS = {
  casavo: 'casavo',
  ucsc: 'ucsc',
} as const

export const UNICREDIT_DOMAIN = 'https://www.unicreditres.it'
