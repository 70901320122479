export type Zone = {
  city?: string
  country?: string
  zone?: string
}
export const parseZoneSlug = (slug: string): Zone => {
  if (!slug) return {}
  const [country, city, zone] = slug.split('/')
  return {
    city,
    country,
    zone,
  }
}

export const parseGeoSlug = (geoSlug: string): string => geoSlug.split('/').reverse()[0]
