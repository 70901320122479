import { SvgProps } from '..'

const Sun: React.FC<SvgProps> = ({ height = 14, width = 14, ...props }) => {
  return (
    <svg
      fill="currentColor"
      height={height}
      viewBox="0 0 14 14"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2.37358 7.58333H0V6.41667H2.37358C2.34967 6.608 2.33333 6.80225 2.33333 7C2.33333 7.19775 2.34967 7.392 2.37358 7.58333ZM4.14342 3.318L2.46283 1.63742L1.638 2.46225L3.31858 4.14283C3.55833 3.83425 3.83483 3.55775 4.14342 3.318ZM10.682 4.14283L12.3626 2.46225L11.5377 1.63742L9.85717 3.318C10.1652 3.55775 10.4417 3.83483 10.682 4.14283ZM7 2.33333C7.19775 2.33333 7.392 2.34967 7.58333 2.37358V0H6.41667V2.37358C6.608 2.34967 6.80225 2.33333 7 2.33333ZM7 11.6667C6.80225 11.6667 6.608 11.6503 6.41667 11.6264V14H7.58333V11.6264C7.392 11.6503 7.19775 11.6667 7 11.6667ZM11.6264 6.41667C11.6503 6.608 11.6667 6.80225 11.6667 7C11.6667 7.19775 11.6503 7.392 11.6264 7.58333H14V6.41667H11.6264ZM9.85717 10.682L11.5372 12.362L12.3626 11.5372L10.6826 9.85717C10.4422 10.1652 10.1658 10.4417 9.85717 10.682ZM3.318 9.85658L1.638 11.5366L2.46283 12.3614L4.14283 10.6814C3.83483 10.4417 3.55775 10.1652 3.318 9.85658ZM4.66667 7C4.66667 8.28683 5.71317 9.33333 7 9.33333C8.28683 9.33333 9.33333 8.28683 9.33333 7C9.33333 5.71317 8.28683 4.66667 7 4.66667C5.71317 4.66667 4.66667 5.71317 4.66667 7ZM10.5 7C10.5 8.93317 8.93317 10.5 7 10.5C5.06683 10.5 3.5 8.93317 3.5 7C3.5 5.06683 5.06683 3.5 7 3.5C8.93317 3.5 10.5 5.06683 10.5 7Z" />
    </svg>
  )
}

export default Sun
