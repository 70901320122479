import { SvgProps } from '..'
import BaseSvg from '../BaseSvg'

const Check: React.FC<SvgProps> = (props) => {
  return (
    <BaseSvg className={props.className} {...props}>
      <path
        clipRule="evenodd"
        d="M14.2778 0.923828L15.3905 3.93038L12.2604 7.06901L12.2516 7.07117L12.2604 7.07248L6.61012 12.7345L6.35707 12.9917L5.45234 13.8954L1.86114 10.2945L0.12793 8.48018L2.15231 6.46422L5.45234 9.77317L14.2778 0.923828Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </BaseSvg>
  )
}

export default Check
