import { kebabCase } from 'lodash/fp'
import getConfig from 'next/config'

import { CateCity } from '@/context/Cities'
import { areaParamEncoder } from '@/utils/cities-mapper'
import provinceUtils from '@/utils/cities/province-utils'
import { parseZoneSlug } from '@/utils/zone-slug-parser'

import { allListings, getHousesForSalesPathSegment, getListingDetailPathSegment } from '../../i18n-routes'

export type Translator = (value: string) => string
type CityValue = string
type ZoneSlug = string
type PropertySlug = string

const { publicRuntimeConfig } = getConfig()

export const linkBuilder = {
  housesForSale: {
    url: (language: Locale): string => {
      return '/' + getHousesForSalesPathSegment(language) + '/'
    },
  },
  housesForSaleCasavoWebsite: {
    url: (language: Locale): string => {
      return `${publicRuntimeConfig.casavoWebsite}/${language}/${
        allListings.find((entry) => entry.locale === language).path
      }/`
    },
  },
  listingByArea: {
    url: (language: Locale, cities: CateCity[], areaValue: CityValue): string => {
      if (!provinceUtils.citySupportsProvince(areaValue, cities)) {
        return linkBuilder.listingByCity.url(language, cities, areaValue)
      }
      const base = linkBuilder.housesForSale.url(language)
      const currentCity = cities.find((city) => city.slug === areaValue)
      const cityString = buildCityName(currentCity, language)
      return `${base}${areaParamEncoder(cityString)}/`
    },
  },
  listingByCity: {
    url: (language: Locale, cities: CateCity[], cityValue: CityValue, canonicalFilterSlug?: string): string => {
      const base = linkBuilder.housesForSale.url(language)
      const currentCity = cities.find((city) => city.slug === cityValue)
      const cityString = buildCityName(currentCity, language)
      const canonicalFilter = canonicalFilterSlug ?? ''
      return `${base}${cityString}/${canonicalFilter}`
    },
  },
  listingByZone: {
    url: (language: Locale, cities: CateCity[], cityValue: string, zoneSlug: ZoneSlug): string => {
      const base = linkBuilder.listingByCity.url(language, cities, cityValue)
      const city = cities.find((city) => city.slug === cityValue)
      const isAValidZone = city?.city_zones?.some((zone) => zone.slug === zoneSlug)

      if (isAValidZone && zoneSlug && zoneSlug.length > 0) {
        const zone = parseZoneSlug(zoneSlug).zone
        return `${base}${zone.toLowerCase()}/`
      } else {
        return base
      }
    },
  },
  listingDetail: {
    url: (language: Locale, propertySlug: PropertySlug): string => {
      return `${linkBuilder.housesForSale.url(language)}${linkBuilder.listingDetailSegment.url(
        language
      )}${propertySlug}/`
    },
  },
  listingDetailSegment: {
    url: (language: Locale): string => {
      return getListingDetailPathSegment(language) + '/'
    },
  },
}

// FIXME we should not use translatedCityName, it's nullable and SEO/URL stuff should be based on slug
const buildCityName = (city: CateCity, locale: Locale) => {
  if (locale === 'en' && city.translations !== undefined && city.translations.length !== 0) {
    return kebabCase(city.translations.find((t) => t.language === 'it').value.toLowerCase())
  } else {
    return kebabCase(city?.translatedCityName || '')
  }
}
