import { linkBuilder } from '@/components/Link/linkBuilder'
import { allListings, favoritesPath } from '@/i18n-routes'

export const buildAbsoluteLocalizedLink = (path: string, lang: Locale) => {
  return window.location.protocol + '//' + window.location.hostname + buildRelativeLocalizedLink(path, lang)
}

export const buildRelativeLocalizedLink = (path: string, lang: Locale) => {
  const langPathPrefix = lang === 'en' ? '' : lang
  return joinPaths(langPathPrefix, trim(path, '/'))
}

export const joinPaths = (...pieces: string[]): string => {
  const path = pieces
    .filter((piece) => piece && piece !== '' && piece !== '/')
    .map((piece) => trim(piece, '/'))
    .join('/')

  if (path === '') {
    return '/'
  }

  if (path.match(/^https?:\/\//)) {
    return `${path}/`
  }

  return `/${path}/`
}

export const getValidReferrerOr = (fallbackUrl: string): string => {
  const referrerUrl = getReferrerUrl()
  if (referrerUrl && hasSameOrigin(referrerUrl) && isAListingPlatformPage(referrerUrl)) {
    return referrerUrl.toString()
  }

  return fallbackUrl
}

const getReferrerUrl = (): URL | undefined => {
  try {
    return new URL(window.document.referrer)
  } catch {
    return undefined
  }
}

const hasSameOrigin = (referrerUrl: URL): boolean => {
  return referrerUrl.origin === window.location.origin
}

const isAListingPlatformPage = (referrerUrl: URL): boolean => {
  return isHouseForSalePage(referrerUrl.pathname) || isFavoritePage(referrerUrl.pathname)
}

const isFavoritePage = (pathname: string) => {
  var regex = new RegExp(`^(/[a-z]{2})?/${favoritesPath}`)
  return regex.test(pathname)
}

const isHouseForSalePage = (pathname: string): boolean => {
  return allListings
    .map((route) => buildRelativeLocalizedLink(route.path, route.locale as Locale))
    .some((houseForSalePath) => pathname.startsWith(houseForSalePath))
}

const trim = (text: string, delimiter: string) => {
  let trimmed = text.startsWith(delimiter) ? text.substring(1) : text
  trimmed = trimmed.endsWith(delimiter) ? trimmed.substring(0, trimmed.length - 1) : trimmed
  return trimmed
}

export const cityPageFromUrl = (url: string) => joinPaths(...url.split('/').filter(Boolean).slice(0, 3))

/**
 * Provides the path to [house-for-sale]/[city] page for the given locale
 *
 * When usedLocale is null or undefined, get the locale from the city slug.
 *
 * Note: it works with zone slugs too
 *
 * @param citySlug should be a valid city slug, i.e. something like "it/san-donato-milanese"
 * @param usedLocale the locale you force to build "house for sale in city" path
 */
export const getHousesForSaleUrlPathByCitySlug = (citySlug: string, usedLocale: Locale) => {
  let [locale, ...restCity] = citySlug.split('/')
  const city = restCity.join('/')
  locale = usedLocale || locale
  return `${linkBuilder.housesForSale.url(locale as Locale)}${city}/`
}
