import { SvgProps } from '..'
import BaseSvg from '../BaseSvg'

const Verified: React.FC<SvgProps> = (props) => {
  return (
    <BaseSvg className={props.className} {...props}>
      <g clipPath="url(#clip0_1756_1147)">
        <path
          d="M15.3333 7.99333L13.7066 6.13333L13.9333 3.67333L11.5266 3.12667L10.2666 1L7.99996 1.97333L5.73329 1L4.47329 3.12667L2.06663 3.66667L2.29329 6.13333L0.666626 7.99333L2.29329 9.85333L2.06663 12.32L4.47329 12.8667L5.73329 15L7.99996 14.02L10.2666 14.9933L11.5266 12.8667L13.9333 12.32L13.7066 9.86L15.3333 7.99333ZM12.7 8.98L12.3266 9.41333L12.38 9.98L12.5 11.28L11.2333 11.5667L10.6733 11.6933L10.38 12.1867L9.71996 13.3067L8.53329 12.7933L7.99996 12.5667L7.47329 12.7933L6.28663 13.3067L5.62663 12.1933L5.33329 11.7L4.77329 11.5733L3.50663 11.2867L3.62663 9.98L3.67996 9.41333L3.30663 8.98L2.44663 8L3.30663 7.01333L3.67996 6.58L3.61996 6.00667L3.49996 4.71333L4.76663 4.42667L5.32663 4.3L5.61996 3.80667L6.27996 2.68667L7.46663 3.2L7.99996 3.42667L8.52663 3.2L9.71329 2.68667L10.3733 3.80667L10.6666 4.3L11.2266 4.42667L12.4933 4.71333L12.3733 6.01333L12.32 6.58L12.6933 7.01333L13.5533 7.99333L12.7 8.98Z"
          fill="currentColor"
        />
        <path
          d="M6.72657 9.1667L5.1799 7.61337L4.19324 8.6067L6.72657 11.1467L11.6199 6.24004L10.6332 5.2467L6.72657 9.1667Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1756_1147">
          <rect fill="white" height="16" width="16" />
        </clipPath>
      </defs>
    </BaseSvg>
  )
}

export default Verified
