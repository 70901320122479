import { Button } from '@casavo/base-ui'
import { ColorsPalette } from '@casavo/base-ui/types/foundations/colors'
import cn from 'classnames'
import { AnimatePresence, motion, PanInfo } from 'framer-motion'
import router from 'next/router'
import { useEffect } from 'react'
import { createPortal } from 'react-dom'

import Icon from '@/components/Icon'
import { PriorityBadge } from '@/components/PriorityCardBadge'
import { useBottomSheet } from '@/context/BottomSheetContext'
import { sprinkles } from '@/design-lib/style-theme/sprinkles.css'
import { backgroundColor } from '@/shared-styles/styles.css'
import { handleClick, handleDrag } from '@/utils/tracking/eventsHandlers'

import {
  borderPriorityBottomSheet,
  bottomSheetCloseButton,
  bottomSheetContentBody,
  bottomSheetHandle,
  bottomSheetOverlay,
  bottomSheetWrapper,
} from './style.css'

type Props = {
  bgColor?: ColorsPalette
  gtmEventCategory?: string
  isPriority?: boolean
  minHeight?: number
}

export const BottomSheet: React.FC<Props> = ({
  bgColor = 'lightBlue',
  gtmEventCategory = 'BottomSheet',
  isPriority,
  minHeight,
}) => {
  const { bottomSheetContent, closeBottomSheet, isBottomSheetOpen } = useBottomSheet()

  const handleDragEnd = (event: any, info: PanInfo) => {
    const shouldClose = info.offset.y > 200 || info.velocity.y > 500
    if (shouldClose) {
      closeBottomSheet()
      handleDrag(gtmEventCategory, 'CloseBottomSheet')
    }
  }

  const animationVariants = {
    exit: {
      transition: {
        damping: 30,
        stiffness: 300,
        type: 'spring',
      },
      y: 1000,
    },
    hidden: { y: 1000 },
    visible: {
      transition: {
        damping: 30,
        stiffness: 300,
        type: 'spring',
      },
      y: 0,
    },
  }

  useEffect(() => {
    const handleRouteChange = () => {
      closeBottomSheet()
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router])

  if (typeof window === 'object') {
    return createPortal(
      <AnimatePresence>
        {isBottomSheetOpen && (
          <>
            <motion.div
              animate={{ opacity: 1 }}
              className={bottomSheetOverlay}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
            />
            <div className={cn(bottomSheetWrapper)} data-testid="bottomsheet">
              <motion.div
                animate="visible"
                drag="y"
                dragConstraints={{ bottom: 0, top: 0 }}
                dragElastic={{ bottom: 0.5, top: 0.2 }}
                dragMomentum={false}
                exit="exit"
                initial="hidden"
                variants={animationVariants}
                onClick={(e) => {
                  e.stopPropagation()
                }}
                onDragEnd={handleDragEnd}
              >
                <div
                  className={cn(
                    bottomSheetContentBody,
                    backgroundColor[bgColor],
                    isPriority && borderPriorityBottomSheet
                  )}
                  style={{ touchAction: 'none' }}
                >
                  {isPriority && <PriorityBadge />}
                  <div className={bottomSheetHandle} />
                  <Button
                    ariaLabel="close dialog"
                    className={bottomSheetCloseButton}
                    Icon={() => <Icon.Close />}
                    shapeVariant="round"
                    styleVariant="tertiary"
                    onClick={() => {
                      closeBottomSheet()
                      handleClick(gtmEventCategory, 'CloseBottomSheet')
                    }}
                  />
                  <div
                    className={sprinkles({ display: 'flex', flexDirection: 'column' })}
                    style={{ minHeight: document.documentElement.clientHeight > minHeight && minHeight }}
                  >
                    {bottomSheetContent}
                  </div>
                </div>
              </motion.div>
            </div>
          </>
        )}
      </AnimatePresence>,
      document.body
    )
  }

  return null
}
