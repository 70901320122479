import getConfig from 'next/config'

/** Will be available on both server and client */
export function getAppEnv(): ListingEnvironment {
  return getConfig().publicRuntimeConfig.environment
}

export const isProd = () => getAppEnv() === 'production'
export const isDevelopment = () => getAppEnv() === 'development'

export const getAppVersion = () => getConfig().publicRuntimeConfig.appVersion
export const getAppRevision = () => getConfig().publicRuntimeConfig.appRevision
