import { SvgProps } from '..'
import BaseSvg from '../BaseSvg'

const Gallery: React.FC<SvgProps> = (props) => {
  return (
    <BaseSvg className={props.className} viewBox="0 0 17 17" {...props}>
      <path
        d="M10.767.944c.699 0 1.31.38 1.636.945h2.708A1.89 1.89 0 0117 3.778V15.11H0V3.778a1.89 1.89 0 011.889-1.89h2.708A1.89 1.89 0 016.233.944h4.534zm-4.534 1.89l-.546.944H1.889v9.444H15.11V3.778h-3.798l-.545-.943-4.535-.002zM8.5 4.721a3.779 3.779 0 11-.001 7.557A3.779 3.779 0 018.5 4.721zm0 1.89a1.888 1.888 0 10-.001 3.779A1.888 1.888 0 008.5 6.61z"
        fill="currentColor"
      />
    </BaseSvg>
  )
}

export default Gallery
