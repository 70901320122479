import { SvgProps } from '..'

const Tree: React.FC<SvgProps> = ({ height = 14, width = 12, ...props }) => {
  return (
    <svg
      fill="currentColor"
      height={height}
      viewBox="0 0 12 14"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M6.58342 14H5.41675V11.1831C5.01133 11.0822 4.5925 10.8902 4.27867 10.661C3.987 10.7485 3.68425 10.7922 3.37508 10.7922C1.60583 10.7922 0.166748 9.331 0.166748 7.53492C0.166748 6.7865 0.412331 6.08008 0.864998 5.50667C0.751248 5.21733 0.691748 4.90875 0.691748 4.59375C0.691748 3.4125 1.51016 2.42317 2.61558 2.19042C3.22341 0.86625 4.54116 0 6.00008 0C7.459 0 8.77733 0.86625 9.38517 2.191C10.49 2.42317 11.3084 3.4125 11.3084 4.59375C11.3084 4.90875 11.2489 5.21792 11.1357 5.50667C11.5884 6.08008 11.8334 6.78592 11.8334 7.53492C11.8334 9.331 10.3937 10.7922 8.62508 10.7922C8.31708 10.7922 8.01491 10.7485 7.72383 10.6616C7.42167 10.8873 7.00983 11.0897 6.58342 11.1912V14ZM4.57092 9.28608C5.12392 10.0858 5.849 10.0934 6.03275 10.0934C6.23167 10.0934 6.94683 10.0497 7.42983 9.28608C7.83875 9.52875 9.11625 9.96333 10.0513 9.03058C10.8616 8.21858 10.9934 6.61558 9.69141 5.69042C10.3436 4.98342 10.1598 4.34233 10.0222 4.04308C9.82208 3.60675 9.38925 3.26492 8.54983 3.28883C8.30717 2.13383 7.11367 1.16667 6.00008 1.16667C4.89525 1.16667 3.67492 2.10933 3.45092 3.28883C2.72466 3.2725 2.25391 3.59392 2.06958 3.87625C1.88 4.16792 1.6805 4.93325 2.30875 5.69042C1.03241 6.77658 1.14791 8.22908 1.94941 9.03058C3.03791 10.1179 4.28917 9.40858 4.57092 9.28608Z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default Tree
