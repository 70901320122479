import { SvgProps } from '..'

const NotificationsOff: React.FC<SvgProps> = ({ height = 14, width = 14, ...props }) => {
  return (
    <svg fill="none" height={height} viewBox="0 0 32 33" width={width} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M0.000687519 23.6941H3.94902V13.8216C3.94902 8.57052 7.36532 4.1174 12.0965 2.56358C12.657 1.06678 14.1019 0 15.7961 0C17.4896 0 18.9341 1.06604 19.4953 2.56367C21.279 3.14905 22.8759 4.14688 24.1734 5.44444L27.6431 1.97475L30.4355 4.76712L2.79238 32.4102L0 29.6179L1.9744 27.6435L0 27.6431L0.000687519 23.6941ZM7.89803 21.7198L21.3809 8.23692C20.0383 6.89421 18.2144 6.03268 16.1903 5.93319L15.7961 5.92352C11.5663 5.92352 8.1131 9.24854 7.9077 13.4274L7.89803 13.8216V21.7198ZM23.6941 16.6146L27.5923 12.7164C27.6259 13.0803 27.6431 13.4489 27.6431 13.8216V23.6941H31.5914L31.5921 27.6431L21.3819 27.6449C21.047 28.5915 20.4782 29.4276 19.7451 30.0838C18.6971 31.0218 17.3132 31.5921 15.7961 31.5921C14.2789 31.5921 12.895 31.0218 11.847 30.0838C11.5615 29.8283 11.3009 29.5454 11.0693 29.2393L16.6146 23.6941H23.6941V16.6146Z"
        fill="#1d1d1b"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default NotificationsOff
