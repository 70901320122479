import { EventAction, sendEventInteraction } from '@/utils/tag-manager'

import { GTMEventCategory } from './eventCategory'

export const handleClick = (eventCategory: GTMEventCategory | string, eventLabel: string, eventValue?: string): void =>
  sendEventInteraction({
    eventAction: 'Click',
    eventCategory,
    eventLabel,
    ...(eventValue ? { eventValue } : {}),
  })

export const handleDrag = (eventCategory: string, eventLabel: string, eventValue?: string): void =>
  sendEventInteraction({
    eventAction: 'Drag',
    eventCategory: eventCategory,
    eventLabel,
    ...(eventValue ? { eventValue } : {}),
  })

export const handleShow = (eventCategory: GTMEventCategory | string, eventLabel: string, eventValue?: string): void =>
  sendEventInteraction({
    eventAction: 'Show',
    eventCategory,
    eventLabel,
    ...(eventValue ? { eventValue } : {}),
  })

export const handleListingDetailClick = (eventLabel: string, eventValue?: string): void =>
  sendEventInteraction({
    eventAction: 'Click',
    eventCategory: 'ListingDetail',
    eventLabel,
    ...(eventValue ? { eventValue } : {}),
  })

export const handleListingDetailSubmit = (eventLabel: string): void =>
  sendEventInteraction({
    eventAction: 'Submit',
    eventCategory: 'ListingDetail',
    eventLabel,
  })

export const handleListingDetailMapClick = (eventLabel: string): void =>
  sendEventInteraction({
    eventAction: 'Click',
    eventCategory: 'ListingDetailMap',
    eventLabel,
  })

export const handleListingDetailGalleryModalClick = (eventLabel: string): void =>
  sendEventInteraction({
    eventAction: 'Click',
    eventCategory: 'ListingDetailGalleryModal',
    eventLabel,
  })

export const handleListingDetailGalleryMobileClick = (eventLabel: string): void =>
  sendEventInteraction({
    eventAction: 'Click',
    eventCategory: 'ListingDetailGalleryMobile',
    eventLabel,
  })

export const handleCasavoPropertyModalClick = (eventLabel: string): void =>
  sendEventInteraction({
    eventAction: 'Click',
    eventCategory: 'CasavoPropertyModal',
    eventLabel,
  })

export const handleListingDetailSimilarPropertiesClick = (eventLabel: string): void =>
  sendEventInteraction({
    eventAction: 'Click',
    eventCategory: 'ListingDetailSimilarProperties',
    eventLabel,
  })

export const handleListingsMapClick = (eventLabel: string): void =>
  sendEventInteraction({
    eventAction: 'Click',
    eventCategory: 'ListingsMap',
    eventLabel,
  })

export const handleListingPageClick = (eventLabel: string): void =>
  sendEventInteraction({
    eventAction: 'Click',
    eventCategory: 'ListingsPage',
    eventLabel,
  })

export const handleListingsPageBreadcrumbClick = (eventLabel: string, eventValue?: string): void =>
  sendEventInteraction({
    eventAction: 'Click',
    eventCategory: 'ListingsPageBreadcrumb',
    eventLabel,
    ...(eventValue ? { eventValue } : {}),
  })

export const handleListingsFilterArea = (eventAction: EventAction, eventLabel: string): void =>
  sendEventInteraction({
    eventAction,
    eventCategory: 'ListingsFilterArea',
    eventLabel,
  })

export const handleClickShare = (eventCategory: string): void =>
  sendEventInteraction({
    eventAction: 'Click',
    eventCategory,
    eventLabel: 'Share',
  })

export const handleClickOpenMap = (eventCategory: string): void =>
  sendEventInteraction({
    eventAction: 'Click',
    eventCategory,
    eventLabel: 'OpenMap',
  })

export const handleListingsMapMobile = (eventAction: EventAction, eventLabel: string): void =>
  sendEventInteraction({
    eventAction,
    eventCategory: 'ListingsMapMobile',
    eventLabel,
  })

export const handleListingsListMobile = (eventAction: EventAction, eventLabel: string): void =>
  sendEventInteraction({
    eventAction,
    eventCategory: 'ListingsListMobile',
    eventLabel,
  })

export const handleClickCallAgency = (eventCategory: string, eventLabel: string = 'Call'): void =>
  sendEventInteraction({
    eventAction: 'Click',
    eventCategory,
    eventLabel,
  })

export const handleClickNavigate = (eventCategory: string): void =>
  sendEventInteraction({
    eventAction: 'Click',
    eventCategory,
    eventLabel: 'Navigate',
  })

export const handleClickOpenCasavoPropertyModal = (eventCategory: string): void =>
  sendEventInteraction({
    eventAction: 'Click',
    eventCategory,
    eventLabel: 'OpenCasavoPropertyModal',
  })

export const handleFormFieldSet = (eventCategory: string, eventLabel: string, eventValue: string = undefined): void =>
  sendEventInteraction({
    eventAction: 'FieldUpdate',
    eventCategory,
    eventLabel,
    eventValue,
  })

export const handleFormSubmit = (eventCategory: string, eventLabel: string): void =>
  sendEventInteraction({
    eventAction: 'Submit',
    eventCategory,
    eventLabel,
  })

export const handleSystem = (eventCategory: string, eventLabel: string, eventValue?: string): void =>
  sendEventInteraction({
    eventAction: 'System',
    eventCategory,
    eventLabel,
    ...(eventValue ? { eventValue } : {}),
  })

export const handleListingsMapFractioningMobile = (eventAction: EventAction, eventLabel: string): void =>
  sendEventInteraction({
    eventAction,
    eventCategory: 'ListingsMapFractioningMobile',
    eventLabel,
  })

export const handleHousesForSale = (eventAction: EventAction, eventLabel: string): void =>
  sendEventInteraction({
    eventAction,
    eventCategory: 'HousesForSale',
    eventLabel,
  })

export const handleBuyerInterestFlow = (eventAction: EventAction, eventLabel: string, eventValue?: string): void =>
  sendEventInteraction({
    eventAction,
    eventCategory: 'BuyerInterestFlow',
    eventLabel,
    ...(eventValue ? { eventValue } : {}),
  })

export const handleUserLoginFlow = (eventAction: EventAction, eventLabel: string, eventValue?: string): void =>
  sendEventInteraction({
    eventAction,
    eventCategory: 'UserLogin',
    eventLabel,
    ...(eventValue ? { eventValue } : {}),
  })

export const handleNewListingsAlertClick = (eventLabel: string, eventValue?: string): void =>
  sendEventInteraction({
    eventAction: 'Click',
    eventCategory: 'NewListingsAlert',
    eventLabel,
    ...(eventValue ? { eventValue } : {}),
  })

export const handleListingsCanonicalFiltersClick = (eventValue: string): void =>
  sendEventInteraction({
    eventAction: 'Click',
    eventCategory: 'ListingsCanonicalFilters',
    eventLabel: 'canonicalFiltersLink',
    eventValue,
  })

export const handleListingsCanonicalZonesClick = (eventValue: string): void =>
  sendEventInteraction({
    eventAction: 'Click',
    eventCategory: 'ListingsCanonicalZones',
    eventLabel: 'canonicalZoneLink',
    eventValue,
  })

export const handleFavouritesClick = (eventLabel: string): void =>
  sendEventInteraction({
    eventAction: 'Click',
    eventCategory: 'Favourites',
    eventLabel,
  })

export const handleFavouritesLegacy = (eventLabel: string): void =>
  sendEventInteraction({
    eventAction: 'System',
    eventCategory: 'LegacyFavourites',
    eventLabel,
  })

/** Send a GTM Event with the Listing Id as eventLabel to have the info on UA
 * @param listingId id of the listing
 * @param onMap whether the event originates from the listing card on map or the listing card on the page
 */
export const sendSupportEventWithListingId = (listingId: string, onMap: boolean) => {
  sendEventInteraction({
    eventAction: 'Click',
    eventCategory: 'ListingsPage',
    eventLabel: onMap ? 'ClickCardOnMap' : 'ClickCardOnPage',
    eventValue: listingId,
  })
}
