import BaseSvg from '../BaseSvg'

const Lock: React.FC = (props) => {
  return (
    <BaseSvg {...props}>
      <g clipPath="url(#clip0_23452_18511)">
        <path
          d="M11.9998 5.83335H11.3332V4.50002C11.3332 2.66002 9.83984 1.16669 7.99984 1.16669C6.15984 1.16669 4.6665 2.66002 4.6665 4.50002V5.83335H3.99984C3.2665 5.83335 2.6665 6.43335 2.6665 7.16669V13.8334C2.6665 14.5667 3.2665 15.1667 3.99984 15.1667H11.9998C12.7332 15.1667 13.3332 14.5667 13.3332 13.8334V7.16669C13.3332 6.43335 12.7332 5.83335 11.9998 5.83335ZM5.99984 4.50002C5.99984 3.39335 6.89317 2.50002 7.99984 2.50002C9.1065 2.50002 9.99984 3.39335 9.99984 4.50002V5.83335H5.99984V4.50002ZM11.9998 13.8334H3.99984V7.16669H11.9998V13.8334ZM7.99984 11.8334C8.73317 11.8334 9.33317 11.2334 9.33317 10.5C9.33317 9.76669 8.73317 9.16669 7.99984 9.16669C7.2665 9.16669 6.6665 9.76669 6.6665 10.5C6.6665 11.2334 7.2665 11.8334 7.99984 11.8334Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_23452_18511">
          <rect fill="white" height="16" transform="translate(0 0.5)" width="16" />
        </clipPath>
      </defs>
    </BaseSvg>
  )
}

export default Lock
