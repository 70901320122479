import { createContext, useCallback, useContext, useMemo, useState } from 'react'

import { getScrollbarWidth } from '@/utils/get-scrollbar-width'
import {
  backToInitialScrollOnModalClose,
  addPositionFixedToBody,
  backToTopOnModalClose,
} from '@/utils/prevent-page-scroll-on-modal-open'

import { useDevice } from './Device'
import { useKeydown } from './KeydownContext'

const initialModalOptions = {
  overlay: false,
}

export type ModalOptions = {
  background?: string
  borderRadius?: number | string
  desktopHeight?: number | string
  desktopWidth?: number | string
  overlay?: boolean
}

export type closeModalOptions = { resetContent?: boolean; shouldBackToInitialScroll?: boolean }

export type ModalContextType = {
  closeModal: (o?: closeModalOptions) => void
  isOpen: boolean
  modalContent: React.ReactNode
  modalOptions: ModalOptions
  openModal: (modalContent: React.ReactNode, options?: ModalOptions) => void
  setIsOpen: (open: boolean) => void
  updateModalContent: (content: React.ReactNode) => void
}

export const ModalContext = createContext<ModalContextType>({
  closeModal: () => null,
  isOpen: false,
  modalContent: null,
  modalOptions: {},
  openModal: () => null,
  setIsOpen: (_b: boolean) => null,
  updateModalContent: () => null,
})

export const ModalContextProvider: React.FC = ({ children }) => {
  const device = useDevice()
  const { isKeydown } = useKeydown()

  const [modalContent, setModalContent] = useState<React.ReactNode>(null)
  const [modalOptions, setModalOptions] = useState(initialModalOptions)
  const [isOpen, setIsOpen] = useState(false)
  let focusedElementBeforeModal = null

  const closeModal = useCallback(
    ({ resetContent = true, shouldBackToInitialScroll = true } = {}) => {
      shouldBackToInitialScroll ? backToInitialScrollOnModalClose() : backToTopOnModalClose()

      setIsOpen(false)
      isKeydown && focusedElementBeforeModal?.focus()

      if (resetContent) {
        setModalContent(null)
      }
    },
    [setIsOpen, setModalContent, device, isKeydown]
  )

  const openModal = useCallback(
    (modalContent: React.ReactNode, options = {}) => {
      focusedElementBeforeModal = isKeydown && document.activeElement

      setIsOpen(true)
      setModalOptions({ ...initialModalOptions, ...options })
      setModalContent(modalContent)
      addPositionFixedToBody(getScrollbarWidth())
    },
    [setIsOpen, setModalContent, device, isKeydown]
  )

  const contextValue: ModalContextType = useMemo(() => {
    return {
      closeModal,
      isOpen,
      modalContent,
      modalOptions,
      openModal,
      setIsOpen,
      updateModalContent: setModalContent,
    }
  }, [closeModal, isOpen, modalContent, openModal])

  return <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>
}

export const useModal = () => {
  return useContext(ModalContext)
}
