import classNames from 'classnames'
import cn from 'classnames'

import { SvgProps } from '..'
import BaseSvg from '../BaseSvg'

import { directionRotate } from './styles.css'

interface Props extends SvgProps {
  className?: string
  direction?: 'top' | 'right' | 'bottom' | 'left'
  fillColor?: string
}

const Chevron: React.FC<Props> = ({ className, direction = 'right', ...props }) => {
  return (
    <BaseSvg
      className={cn(directionRotate[direction], className)}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M15.5 5.83486L8 13.4395L0.5 5.83486L1.87621 4.43945L8 10.6479L14.1238 4.43945L15.5 5.83486Z"
        fill={props.fillColor || 'currentColor'}
        fillRule="evenodd"
      />
    </BaseSvg>
  )
}

export default Chevron
