import { sprinkles } from '@/design-lib/style-theme/sprinkles.css'
export type Props = React.SVGProps<SVGSVGElement>

const BaseSvg: React.FC<Props> = ({ children, ...props }) => (
  <svg
    className={sprinkles({ fill: 'currentcolor' })}
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
  </svg>
)

export default BaseSvg
