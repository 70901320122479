export const allListings: { locale: Locale; path: string }[] = [
  { locale: 'en', path: 'houses-for-sale' },
  { locale: 'it', path: 'case-in-vendita' },
  { locale: 'fr', path: 'acheter-bien' },
]

const detailListing: { locale: Locale; path: string }[] = [
  { locale: 'en', path: 'property' },
  { locale: 'it', path: 'annunci' },
  { locale: 'fr', path: 'annonces' },
]

export const allListingsRecord: Record<string, string> = allListings.reduce((acc, item) => {
  return { ...acc, [item.locale]: item.path }
}, {})
export const buyerInterestFlowPath: Record<Locale, string> = {
  en: 'i-wish',
  fr: 'i-wish',
  it: 'i-wish',
}

export const loginPath = 'login'
export const profilePath = 'profile'
export const favoritesPath = `${profilePath}/favorites`

export const suggestedFeedPath = 'profile/suggested'
export const suggestedOnboardingPath = 'suggested/onboarding'
export const suggestedFeedSettingsPathSegment = 'profile/suggested/settings'

export const affordabilityPath = 'affordability/'

export const listingRedirectPath = 'listings'

export const getPriorityThankyouPage = (slug: string, city?: string) => {
  return city ? `${slug}/thankyou?redirectCity=${city}` : `${slug}/thankyou`
}

export const sellersFlowUrlPath: Record<Locale, string> = {
  en: '/get-free-offer/',
  fr: '/fr/offre-gratuite/',
  it: '/it/offerta-gratuita/',
}

export const footerTermsPath: Record<Locale, string> = {
  en: '/terms-and-conditions/',
  fr: '/fr/conditions-generales/',
  it: '/it/termini-e-condizioni/',
}

export const footerPrivacyPath: Record<Locale, string> = {
  en: '/pdf/privacy-policy-en.pdf/',
  fr: '/pdf/privacy-policy-fr.pdf/',
  it: '/pdf/privacy-policy-it.pdf/',
}

/**
 * produces the base path of "houses for sale" section, without heading and trailing slashes
 * i.e.
 *  - en -> 'house-for-sale'
 *  - it -> 'it/case-in-vendita'
 *  - es -> 'es/casas-en-venta'
 *  - ...
 *
 * @param language
 */
export const getHousesForSalesPathSegment = (language: Locale): string => {
  const urlCountryPrefix = language !== 'en' ? `${language}/` : ''
  const urlSectionPrefix = allListings.filter(({ locale }) => language === locale)[0].path

  return `${urlCountryPrefix}${urlSectionPrefix}`
}

export const getListingDetailPathSegment = (language: Locale): string => {
  return detailListing.filter(({ locale }) => language === locale)[0].path
}
export const isValidSegmentForHome = (locale: Locale, segment: string): boolean => allListingsRecord[locale] === segment
