import { SvgProps } from '..'
import BaseSvg from '../BaseSvg'

const Clock: React.FC<SvgProps> = (props) => {
  return (
    <BaseSvg {...props}>
      <path
        clipRule="evenodd"
        d="M7 0.333313C10.6819 0.333313 13.6667 3.31808 13.6667 6.99998C13.6667 10.6819 10.6819 13.6666 7 13.6666C3.3181 13.6666 0.333328 10.6819 0.333328 6.99998C0.333328 3.31808 3.3181 0.333313 7 0.333313ZM6.99999 1.99998C4.23857 1.99998 1.99999 4.23856 1.99999 6.99998C1.99999 9.7614 4.23857 12 6.99999 12C9.76142 12 12 9.7614 12 6.99998C12 4.23856 9.76142 1.99998 6.99999 1.99998ZM7.83333 4.49998V6.99998H9.49999V8.66665H6.16666V4.49998H7.83333Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </BaseSvg>
  )
}

export default Clock
