import React, { createContext, useContext, PropsWithChildren, useEffect, useState } from 'react'

import { FavoriteButtonGtmTouchpoint } from '@/components/FavouriteButton'
import glugluApi, { FavoriteListing } from '@/lib/api-gluglu-auth'
import { Selling_Prices } from '@/types/cate-graphql-generated-types'
import { CasavoInventory } from '@/types/listing'
import { isStorageSupported } from '@/utils/isStorageSupported'
import { handleFavouritesClick, handleFavouritesLegacy } from '@/utils/tracking/eventsHandlers'

import { useAuthentication } from './AuthProvider'

export type FavoriteActionResult = 'Added' | 'Removed' | 'Error' | 'Unauthorized' | 'Loading'
type FavoriteContextType = {
  addFavorite: (listingId: string, labelForTracking: FavoriteButtonGtmTouchpoint) => Promise<FavoriteActionResult>
  favorites: FavoriteListing[]
  isFavorite: (listingId: string) => boolean
  isLoading: boolean
  refreshFavourite: () => void
  removeFavorite: (listingId: string) => Promise<FavoriteActionResult>
  toggleFavorite: (listingId: string, labelForTracking: FavoriteButtonGtmTouchpoint) => Promise<FavoriteActionResult>
}

export const FavoriteContext = createContext<FavoriteContextType>({
  addFavorite: undefined,
  favorites: [],
  isFavorite: () => false,
  isLoading: true,
  refreshFavourite: undefined,
  removeFavorite: undefined,
  toggleFavorite: undefined,
})

export const useFavorites = () => {
  return useContext(FavoriteContext)
}

export const FavoritesProvider: React.FC<PropsWithChildren<React.ReactNode>> = ({ children }) => {
  const { isLoading: isUserLoading, user } = useAuthentication()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [favorites, setFavorites] = useState<FavoriteListing[]>([])

  useEffect(() => {
    if (isUserLoading) {
      setFavorites([])
      return
    }

    if (!user) {
      setFavorites([])
      setIsLoading(false)
      return
    }

    legacyRetrieveAndSaveLocalStorageFavorites()
      .finally(retrieveFavoritesFromGluglu)
      .finally(completeAddingFavoriteForJustLoggedInUser)
      .finally(() => setIsLoading(false))
  }, [isUserLoading, user])

  const retrieveFavoritesFromGluglu = () => {
    return glugluApi
      .allFavorites()
      .then(setFavorites)
      .catch(() => {})
  }

  // to be remove once handleFavouritesLegacy('AddedToGluGlu') is not trigged anymore (check on google analytics)
  const legacyRetrieveAndSaveLocalStorageFavorites = async (): Promise<void | void[]> => {
    if (localStorage.getItem('@CASAVO_LISTINGS_FAVOURITES')) {
      const oldSavedFavorites: Array<{ id: string }> = JSON.parse(localStorage.getItem('@CASAVO_LISTINGS_FAVOURITES'))
      return await Promise.all(
        oldSavedFavorites.map(async (oldFavorite) => await glugluApi.addFavorite(oldFavorite.id))
      ).then(() => {
        handleFavouritesLegacy('AddedFromLocalStorageToGluGlu')
        localStorage.removeItem('@CASAVO_LISTINGS_FAVOURITES')
      })
    }
    return Promise.resolve()
  }

  const completeAddingFavoriteForJustLoggedInUser = async (): Promise<void> => {
    const favoriteData =
      isStorageSupported(() => sessionStorage) && sessionStorage.getItem('@CASAVO_LISTINGS_ID_FAVOURITES_TO_BE_ADDED')

    if (favoriteData === null) return

    const { labelForTracking, listingId } = JSON.parse(favoriteData)

    if (!isFavorite(listingId)) {
      await addFavorite(listingId, labelForTracking)
    }

    handleFavouritesClick(`AddedFrom${labelForTracking}AfterSocialLogin`)
    isStorageSupported(() => sessionStorage) && sessionStorage.removeItem('@CASAVO_LISTINGS_ID_FAVOURITES_TO_BE_ADDED')
  }

  const isFavorite = (listingId: string) => favorites.some((favorite) => favorite.listing_id === listingId)

  const toggleFavorite = async (
    listingId: string,
    labelForTracking: FavoriteButtonGtmTouchpoint
  ): Promise<FavoriteActionResult> => {
    if (isLoading) return 'Loading'
    if (isFavorite(listingId)) {
      return removeFavorite(listingId)
    }
    return addFavorite(listingId, labelForTracking)
  }

  const addFavorite = async (
    listingId: string,
    labelForTracking: FavoriteButtonGtmTouchpoint
  ): Promise<FavoriteActionResult> => {
    if (!user) {
      isStorageSupported(() => sessionStorage) &&
        sessionStorage.setItem(
          '@CASAVO_LISTINGS_ID_FAVOURITES_TO_BE_ADDED',
          JSON.stringify({ labelForTracking, listingId })
        )
      return 'Unauthorized'
    }

    setFavorites((prev) => [{ added_at: new Date().toISOString(), listing_id: listingId }, ...prev])
    const addedFavoriteResponse = await glugluApi
      .addFavorite(listingId)
      .then(() => 'Added')
      .catch(() => {
        setFavorites((prev) => prev.filter((f) => f.listing_id !== listingId))
        return 'Error'
      })

    return addedFavoriteResponse as FavoriteActionResult
  }

  const removeFavorite = async (listingId: string, preventFilter: boolean = false): Promise<FavoriteActionResult> => {
    if (isLoading) return 'Loading'
    if (!user) return 'Unauthorized'

    !preventFilter && setFavorites((prev) => prev.filter((f) => f.listing_id !== listingId))
    const removedFavoriteResponse = await glugluApi
      .removeFavorite(listingId)
      .then(() => 'Removed')
      .catch(() => {
        setFavorites((prev) => [{ added_at: new Date().toISOString(), listing_id: listingId }, ...prev])
        return 'Error'
      })
    return removedFavoriteResponse as FavoriteActionResult
  }

  const removeFavoritePreventFiltering = async (listingId: string): Promise<FavoriteActionResult> => {
    return removeFavorite(listingId, true)
  }

  return (
    <FavoriteContext.Provider
      value={{
        addFavorite: async (listingId, labelForTracking) =>
          isLoading ? 'Loading' : addFavorite(listingId, labelForTracking),
        favorites,
        isFavorite,
        isLoading,
        refreshFavourite: retrieveFavoritesFromGluglu,
        removeFavorite: removeFavoritePreventFiltering,
        toggleFavorite,
      }}
    >
      {children}
    </FavoriteContext.Provider>
  )
}

export type EnrichedFavoriteType = {
  added_at?: string
  city?: string
  commercialArea?: number
  displayed_at?: string
  image?: string
  inventory?: CasavoInventory
  listing_id: string
  noLongerAvailable?: boolean
  price?: Selling_Prices
  privateNegotiation: boolean
  slug?: string
  title?: string
  zoneSlug?: string
}
