import BaseSvg, { Props } from '../BaseSvg'

const InfoSolid: React.FC<Props> = (props) => (
  <BaseSvg className={props.className} viewBox="0 0 12 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      clipRule="evenodd"
      d="M7 0.333313C10.6819 0.333313 13.6667 3.31808 13.6667 6.99998C13.6667 10.6819 10.6819 13.6666 7 13.6666C3.3181 13.6666 0.333336 10.6819 0.333336 6.99998C0.333336 3.31808 3.3181 0.333313 7 0.333313ZM7.83334 6.16665H6.16667V11.1666H7.83334V6.16665ZM7 2.83331C6.30965 2.83331 5.75 3.39296 5.75 4.08331C5.75 4.77367 6.30965 5.33331 7 5.33331C7.69036 5.33331 8.25 4.77367 8.25 4.08331C8.25 3.39296 7.69036 2.83331 7 2.83331Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </BaseSvg>
)

export default InfoSolid
