import { ColorsPalette } from '@casavo/base-ui/types/foundations/colors'
import { createContext, useContext, useState } from 'react'

import { BottomSheet } from '@/design-lib/components/BottomSheet'

type BottomSheetOptions = { bgColor?: ColorsPalette; isPriority?: boolean; minHeight?: number }

type BottomSheetContextType = {
  bottomSheetContent: React.ReactNode
  closeBottomSheet: VoidFunction
  isBottomSheetOpen: boolean
  openBottomSheet: (content: React.ReactNode, options?: BottomSheetOptions) => void
}

const BottomSheetContext = createContext<BottomSheetContextType>({
  bottomSheetContent: null,
  closeBottomSheet: () => {},
  isBottomSheetOpen: false,
  openBottomSheet: () => null,
})

export const BottomSheetProvider = ({ children }) => {
  const [isBottomSheetOpen, setBottomSheetIsOpen] = useState(false)
  const [bottomSheetContent, setBottomSheetContent] = useState<React.ReactNode>()
  const [bottomSheetOptions, setBottomSheetOptions] = useState<BottomSheetOptions>({})

  const openBottomSheet = (content: React.ReactNode, options: BottomSheetOptions) => {
    setBottomSheetIsOpen(true)
    setBottomSheetOptions(options)
    setBottomSheetContent(content)
  }

  const closeBottomSheet = () => {
    setBottomSheetIsOpen(false)
  }

  return (
    <BottomSheetContext.Provider value={{ bottomSheetContent, closeBottomSheet, isBottomSheetOpen, openBottomSheet }}>
      <BottomSheet {...bottomSheetOptions} />
      {children}
    </BottomSheetContext.Provider>
  )
}

export const useBottomSheet = () => useContext(BottomSheetContext)
