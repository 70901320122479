import { SvgProps } from '..'
import BaseSvg from '../BaseSvg'

const Bedroom: React.FC<SvgProps> = (props) => {
  return (
    <BaseSvg {...props}>
      <path d="M13 0c1.105 0 2 .895 2 2v6c.552 0 1 .448 1 1v6h-3c0 .552-.448 1-1 1s-1-.448-1-1H5c0 .552-.448 1-1 1s-1-.448-1-1H0V9c0-.552.448-1 1-1V2c0-1.105.895-2 2-2h10zm1 10H2v3h12v-3zm-1-8H3v6h1.05C4.017 7.84 4 7.671 4 7.5 4 6.12 5.12 5 6.5 5h3C10.88 5 12 6.12 12 7.5c0 .171-.017.339-.05.5H13V2zM9.5 7h-3c-.276 0-.5.224-.5.5 0 .245.177.45.41.492L6.5 8h3c.276 0 .5-.224.5-.5 0-.245-.177-.45-.41-.492L9.5 7z" />
    </BaseSvg>
  )
}

export default Bedroom
