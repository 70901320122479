import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import React, { createContext, useContext, useRef } from 'react'

import { linkBuilder } from '@/components/Link/linkBuilder'
import { PageType } from '@/utils/get-page-type-from-params'
import { cityPageFromUrl, getHousesForSaleUrlPathByCitySlug } from '@/utils/navigation'

export type Props = {
  currentPage: string
  isOnCityPage: boolean
  isOnFavouritePage: boolean
  isOnListingDetailPage: boolean
  isOnSuggestedPage: boolean
  returnToLastCityPage: () => void
  setLastCityFromListing: (string) => void
}

const defaultContextValue: Props = {
  currentPage: '/',
  isOnCityPage: false,
  isOnFavouritePage: false,
  isOnListingDetailPage: false,
  isOnSuggestedPage: false,
  returnToLastCityPage: () => {},
  setLastCityFromListing: () => {},
}

export const UserNavigationContext = createContext<Props>(defaultContextValue)

export const useUserNavigation = () => {
  const context = useContext(UserNavigationContext)
  if (!context) {
    throw new Error(`useUserNavigation must be used within a UserNavigationContext`)
  }
  return context
}

export const UserNavigationProvider: React.FC<{ pageType: PageType }> = ({ children, pageType }) => {
  const router = useRouter()
  const previousCityPageUrl = useRef<string>()
  const localeInUrl = router.locale !== 'en' ? `/${router.locale}` : ''

  const isOnSuggestedPage = pageType === 'suggested'
  const isOnFavouritePage = pageType === 'favorites'
  const isOnCityPage = pageType === 'city' || pageType === 'zone' || pageType === 'canonical-filter'
  const isOnListingDetailPage = pageType === 'detail' || pageType === 'not-found'

  if (isOnCityPage) {
    previousCityPageUrl.current = `${localeInUrl}${router.asPath}`
  } else if (isOnListingDetailPage && !previousCityPageUrl.current) {
    previousCityPageUrl.current = cityPageFromUrl(`${localeInUrl}${router.asPath}`)
  }

  const returnToLastCityPage = () => {
    if (previousCityPageUrl.current) {
      router.push(previousCityPageUrl.current, previousCityPageUrl.current, { locale: false })
    } else {
      window.location.href = linkBuilder.housesForSale.url(router.locale)
    }
  }

  const setLastCityFromListing = (slug) => {
    if (!previousCityPageUrl.current) {
      previousCityPageUrl.current = getHousesForSaleUrlPathByCitySlug(slug, router.locale)
    }
  }

  return (
    <UserNavigationContext.Provider
      value={{
        currentPage: previousCityPageUrl.current,
        isOnCityPage,
        isOnFavouritePage,
        isOnListingDetailPage,
        isOnSuggestedPage,
        returnToLastCityPage,
        setLastCityFromListing,
      }}
    >
      {children}
    </UserNavigationContext.Provider>
  )
}
