export const rawMq = {
  desktop: 'min-width: 1024px',
  tablet: 'min-width: 768px',
  xdesktop: 'min-width: 1200px',
  xlarge: 'min-width: 992px',
  xxlarge: 'min-width: 1440px',
}

export const mq = {
  desktop: `@media screen and (${rawMq.desktop})`,
  tablet: `@media screen and (${rawMq.tablet})`,
  xdesktop: `@media screen and (${rawMq.xdesktop})`,
  xlarge: `@media screen and (${rawMq.xlarge})`,
  xxlarge: `@media screen and (${rawMq.xxlarge})`,
}

export const vanillaMq = {
  desktop: `screen and (${rawMq.desktop})`,
  tablet: `screen and (${rawMq.tablet})`,
  xlarge: `screen and (${rawMq.xlarge})`,
}
