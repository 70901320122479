export const isStorageSupported = (getStorage: () => Storage) => {
  try {
    const key = '__some_random_key_you_are_not_going_to_use__'
    getStorage().setItem(key, key)
    getStorage().removeItem(key)
    return true
  } catch (e) {
    return false
  }
}
