import { SvgProps } from '..'
import BaseSvg from '../BaseSvg'

const Warning: React.FC<SvgProps> = (props) => {
  return (
    <BaseSvg className={props.className} {...props}>
      <path d="M0 19H22L11 0L0 19ZM12 16H10V14H12V16ZM12 12H10V8H12V12Z" fill="#FF991F" />
    </BaseSvg>
  )
}

export default Warning
