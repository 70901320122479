import { User } from '@/types/authentication'

interface LoginProvider {
  label: string | null
  name: string
  value: string
}

interface RecoverPasswordStarted {
  csrfToken: string
  email: string
  submissionUrl: string
}

interface RecoverPasswordVerified {
  updatePasswordFlowId: string
}

export interface AuthCredentials {
  identifier: string
  password: string
}

export enum SignUpError {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  ALREADY_LOGGED_IN = 'ALREADY_LOGGED_IN',
  GENERIC = 'GENERIC',
  TOO_SIMPLE_PASSWORD = 'TOO_SIMPLE_PASSWORD',
}

export enum LoginError {
  ALREADY_LOGGED_IN = 'ALREADY_LOGGED_IN',
  GENERIC = 'GENERIC',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
}

export enum LogoutError {
  ALREADY_LOGGED_OUT = 'ALREADY_LOGGED_OUT',
  GENERIC = 'GENERIC',
}

export enum RecoverPasswordVerifyError {
  GENERIC = 'GENERIC',
  INVALID_VERIFICATION_CODE = 'INVALID_VERIFICATION_CODE',
  NOT_STARTED = 'NOT_STARTED',
}

export enum RecoverPasswordStartError {
  GENERIC = 'GENERIC',
}

export enum RecoverPasswordUpdateError {
  GENERIC = 'GENERIC',
  NOT_VERIFIED = 'NOT_VERIFIED',
  TOO_SIMPLE_PASSWORD = 'TOO_SIMPLE_PASSWORD',
}

export type EnabledSocialLoginProvider = 'google'

export function createAuthClient(kratosEndpoint: string) {
  let recoverPasswordStarted: RecoverPasswordStarted | null = null
  let recoverPasswordVerified: RecoverPasswordVerified | null = null

  return {
    async fetchCurrentUser(): Promise<User> {
      return fetch(`${kratosEndpoint}/sessions/whoami`, {
        credentials: 'include',
        headers: { Accept: 'application/json' },
      })
        .then((resp) => {
          if (!resp.ok) {
            throw new Error(`${resp.status}: ${resp.statusText}`)
          }
          return resp.json()
        })
        .then((data) => {
          const isRecoveryMode =
            data.authentication_methods &&
            data.authentication_methods.length > 0 &&
            data.authentication_methods[0]?.method === 'code_recovery'
          if (isRecoveryMode) {
            throw new Error('401 - You are in Recovery Mode')
          }
          return {
            casavo_user_id: data.casavo_user.id,
            email: data.identity.traits.email,
            first_name: data.identity.traits.first_name,
            id: data.identity.id,
            isLoggedBySocial: data.authentication_methods[0]?.method === 'oidc',
            last_name: data.identity.traits.last_name,
          }
        })
    },

    getRecoveringEmail(): string | null {
      return recoverPasswordStarted?.email
    },

    async loginWithEmailAndPassword(credentials: AuthCredentials): Promise<User> {
      return fetchLoginParams()
        .then((params) => {
          return fetch(params.loginUrl, {
            body: JSON.stringify({
              csrf_token: params.csrfToken,
              identifier: credentials.identifier,
              method: 'password',
              password: credentials.password,
            }),
            credentials: 'include',
            headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
            method: 'post',
          })
        })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.session && data.session.identity) {
            return {
              casavo_user_id: data.casavo_user.id,
              email: data.session.identity.traits.email,
              first_name: data.session.identity.traits.first_name || null,
              id: data.session.identity.id,
              isLoggedBySocial: false,
              last_name: data.session.identity.traits.last_name || null,
            }
          }
          if (data?.ui?.messages && data?.ui?.messages[0]?.id === 4000006) {
            throw new Error(LoginError.INVALID_CREDENTIALS)
          }

          throw new Error(LoginError.GENERIC)
        })
        .catch((e) => {
          if (!(e.message in LoginError)) {
            throw new Error(LoginError.GENERIC)
          }
          throw e
        })
    },

    async loginWithProvider(provider: EnabledSocialLoginProvider, returnUrl: string): Promise<void> {
      const params = await fetchLoginParams(encodeURIComponent(returnUrl))

      return fetch(params.loginUrl, {
        body: JSON.stringify({
          csrf_token: params.csrfToken,
          provider: provider,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'post',
      })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.redirect_browser_to) {
            window.location.href = data.redirect_browser_to
          } else {
            // TODO: parse correctly the error messages
            console.error(data)
            throw new Error(JSON.stringify(data))
          }
        })
        .catch(console.error)
    },

    async logout(): Promise<void> {
      try {
        const params = await fetchLogoutParams()
        return fetch(params.logoutUrl, {
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }).then(() => {})
      } catch (e) {
        if (!(e.message in LogoutError)) {
          throw new Error(LogoutError.GENERIC)
        }

        throw e
      }
    },

    async recoverPasswordStart(email: string): Promise<void> {
      try {
        const params = await fetchRecoverPasswordStartParams()

        const response = await fetch(params.recoverUrl, {
          body: JSON.stringify({
            csrf_token: params.csrfToken,
            email: email,
            method: 'code',
          }),
          credentials: 'include', // this should help to handle already logged user case (?)
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
          method: 'post',
        })

        if (!response.ok) {
          throw new Error(RecoverPasswordStartError.GENERIC)
        }

        const body = await response.json()

        recoverPasswordStarted = {
          csrfToken: body.ui.nodes.find((node: any) => node.attributes.name === 'csrf_token').attributes.value,
          email: email,
          submissionUrl: body.ui.action,
        }
      } catch (e) {
        if (!(e.message in RecoverPasswordStartError)) {
          throw new Error(RecoverPasswordStartError.GENERIC)
        }

        throw e
      }
    },

    // TODO: resend code
    /*
      const recoverResendEmail = recoverySubmitted.ui.nodes.find((node: any) =>
        node.attributes.name === 'email'
      ).attributes.value
      */
    // {
    //   csrf_token: 'token',
    //   method: 'code',
    //   email: 'a@b.c'
    // }

    async recoverPasswordUpdate(newPassword: string): Promise<void> {
      try {
        if (!recoverPasswordVerified) {
          throw new Error(RecoverPasswordUpdateError.NOT_VERIFIED)
        }

        const settingsFlowResponse = await fetch(
          `${kratosEndpoint}/self-service/settings/flows?id=${recoverPasswordVerified.updatePasswordFlowId}`,
          {
            credentials: 'include',
            headers: { Accept: 'application/json' },
          }
        ).then((resp) => resp.json())

        const settingsParams = {
          csrfToken: settingsFlowResponse.ui.nodes.find((node: any) => node.attributes.name === 'csrf_token').attributes
            .value,
          url: settingsFlowResponse.ui.action,
        }

        const response = await fetch(settingsParams.url, {
          body: JSON.stringify({
            csrf_token: settingsParams.csrfToken,
            method: 'password',
            password: newPassword,
          }),
          credentials: 'include',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
          method: 'post',
        })

        if (response.status === 400) {
          throw new Error(RecoverPasswordUpdateError.TOO_SIMPLE_PASSWORD)
        } else if (!response.ok) {
          throw new Error(RecoverPasswordUpdateError.GENERIC)
        }
      } catch (e) {
        if (!(e.message in RecoverPasswordUpdateError)) {
          throw new Error(RecoverPasswordUpdateError.GENERIC)
        }

        throw e
      }
    },

    async recoverPasswordVerify(code: string): Promise<void> {
      try {
        if (!recoverPasswordStarted) {
          throw new Error(RecoverPasswordVerifyError.NOT_STARTED)
        }

        if (recoverPasswordVerified) {
          return
        }

        const response = await fetch(recoverPasswordStarted.submissionUrl, {
          body: JSON.stringify({
            code: code,
            csrf_token: recoverPasswordStarted.csrfToken,
            method: 'code',
          }),
          credentials: 'include', // this should help to handle already logged user case (?)
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
          method: 'post',
        })
        const data = await response.json()

        // check if we can update the password using the settings flow
        if (response.status !== 422 || !data.redirect_browser_to) {
          throw new Error(RecoverPasswordVerifyError.INVALID_VERIFICATION_CODE)
        }

        // we need only the flow from: "http://localhost:3000/setting?flow=19a2d617-676b-4226-888a-61e3e612d7bb"
        // TODO: do better: use URL class
        const settingsFlow = data.redirect_browser_to.split('flow=')[1]

        recoverPasswordVerified = {
          updatePasswordFlowId: settingsFlow,
        }
      } catch (e) {
        if (!(e.message in RecoverPasswordVerifyError)) {
          throw new Error(RecoverPasswordVerifyError.GENERIC)
        }

        throw e
      }
    },

    async registerUser(credentials: AuthCredentials): Promise<User> {
      return fetchRegisterUserParams()
        .then((params) => {
          return fetch(params.registrationUrl, {
            body: JSON.stringify({
              csrf_token: params.csrfToken,
              method: 'password',
              password: credentials.password,
              'traits.email': credentials.identifier,
            }),
            credentials: 'include',
            headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
            method: 'post',
          })
        })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.error) {
            throw data.error.id
          }

          if (data?.identity) {
            return {
              casavo_user_id: data.casavo_user.id,
              email: data.identity.traits.email,
              first_name: data.identity.traits.first_name || null,
              id: data.identity.id,
              isLoggedBySocial: false,
              last_name: data.identity.traits.last_name || null,
            }
          }

          if (data?.ui?.messages && data?.ui?.messages[0]?.id === 4000007) {
            throw new Error(SignUpError.ALREADY_EXISTS)
          } else if (
            data?.ui?.nodes
              ?.find((node) => node.attributes.name === 'password')
              .messages.some((message) => message.id === 4000005)
          ) {
            throw new Error(SignUpError.TOO_SIMPLE_PASSWORD)
          } else {
            throw new Error(SignUpError.GENERIC)
          }
        })
        .catch((e) => {
          if (!(e.message in SignUpError)) {
            throw new Error(SignUpError.GENERIC)
          }
          throw e
        })
    },
  }

  async function fetchRegisterUserParams(): Promise<{ csrfToken: string; registrationUrl: string }> {
    return await fetch(`${kratosEndpoint}/self-service/registration/browser`, {
      credentials: 'include',
      headers: { Accept: 'application/json' },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.error) {
          if (data.error.id === 'session_already_available') {
            throw new Error(SignUpError.ALREADY_LOGGED_IN)
          }
          throw new Error(LogoutError.GENERIC)
        }

        return {
          csrfToken: data.ui.nodes.find((node: any) => node.attributes.name === 'csrf_token').attributes.value,
          registrationUrl: data.ui.action,
        }
      })
  }

  async function fetchLoginParams(returnUrl: string | null = null): Promise<{
    csrfToken: string
    loginUrl: string
    providers: LoginProvider[]
  }> {
    var returnPath = ''
    if (returnUrl) {
      returnPath = `&return_to=${returnUrl}`
    }
    return await fetch(`${kratosEndpoint}/self-service/login/browser?refresh=true${returnPath}`, {
      credentials: 'include',
      headers: { Accept: 'application/json' },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.error) {
          if (data.error.id === 'session_already_available') {
            throw new Error(LoginError.ALREADY_LOGGED_IN)
          }
          throw new Error(LogoutError.GENERIC)
        }

        return {
          csrfToken: data.ui.nodes.find((node: any) => node.attributes.name === 'csrf_token').attributes.value,
          loginUrl: data.ui.action,
          providers: data.ui.nodes
            .filter((node: any) => node.group === 'oidc' && !node.attributes.disabled)
            .map((node: any) => ({
              label: node.meta?.label?.text,
              name: node.attributes.name,
              value: node.attributes.value,
            })),
        }
      })
  }

  async function fetchLogoutParams(): Promise<{ logoutUrl: string }> {
    return await fetch(`${kratosEndpoint}/self-service/logout/browser`, {
      credentials: 'include',
      headers: { Accept: 'application/json' },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.error) {
          if (data.error.id === 'session_inactive') {
            throw new Error(LogoutError.ALREADY_LOGGED_OUT)
          }
          throw new Error(LogoutError.GENERIC)
        }

        return {
          logoutUrl: data.logout_url,
        }
      })
  }

  async function fetchRecoverPasswordStartParams(): Promise<{ csrfToken: string; recoverUrl: string }> {
    const response = await fetch(`${kratosEndpoint}/self-service/recovery/browser`, {
      credentials: 'include', // this should help to handle already logged user case (?)
      headers: { Accept: 'application/json' },
    }).then((resp) => resp.json())

    if (response.error) {
      throw new Error(response.error.id)
    }

    return {
      csrfToken: response.ui.nodes.find((node: any) => node.attributes.name === 'csrf_token').attributes.value,
      recoverUrl: response.ui.action,
    }
  }
}
