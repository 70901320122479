import { SvgProps } from '..'
import BaseSvg from '../BaseSvg'

const Star: React.FC<SvgProps> = ({ ...props }) => {
  return (
    <BaseSvg {...props}>
      <path
        d="M8.00016 11.5133L12.1202 14L11.0268 9.31331L14.6668 6.15998L9.8735 5.75331L8.00016 1.33331L6.12683 5.75331L1.3335 6.15998L4.9735 9.31331L3.88016 14L8.00016 11.5133Z"
        fill="currentColor"
      />
    </BaseSvg>
  )
}

export default Star
