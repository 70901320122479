import { kebabCase } from 'lodash/fp'

import { CateCity } from '@/context/Cities'
import { sanitizeCityName } from '@/utils/cities-utils'

import { getLocaleByCountryFilter } from './locale-variables'

export const isCityReferenceArea = (city: CateCity): boolean => city !== undefined && city.referenceArea === city.slug

export const cityParamEncoder = (city: string): string => kebabCase(city)

const cityParamDecoder = (city: string | string[]): string | null => {
  if (city === undefined) {
    return null
  }
  return (Array.isArray(city) ? city[0] : city).replace(/-/g, ' ')
}

/**
 * Extract from an array of {CateCity} the one with the given name.
 *
 * Nome: match is performed using translated city name for city.
 *
 * @example
 * // given an array of CateCity with names translated in "it"
 * const cities = await getCitiesByArea("it", "milano")
 *
 * // you don't need kebab case here
 * const city = findCity("sesto san giovanni", cities)
 * // but you can use kebab case, as well as array with single string
 * const anotherCity == findCity(["san-giuliano-milanese"], cities)
 *
 * @param cityParam
 * @param cities
 */
export const findCity = <T extends CateCity>(cityParam: string | string[], cities: T[]): T => {
  return cities.find((city) => {
    // first check based on slug, assuming cityParam is "kebab case"
    if (city.slug.split('/')[1] === (Array.isArray(cityParam) ? cityParam[0] : cityParam)) return true

    // second check: old, crappy code using city translation...
    const cityLocale = getLocaleByCountryFilter(city.country as CateCountry)
    const localeCityName = city.translations
      .find((translation) => translation.language === cityLocale)
      .value.toLowerCase()
    return sanitizeCityName(localeCityName) === cityParamDecoder(cityParam)
  })
}

type Zone = {
  slug?: string
}
const findZone = <T extends Zone>(zoneParam: string | string[], zones: T[]): T =>
  zones.find((zone) => zone.slug?.split('/')[2] === (Array.isArray(zoneParam) ? zoneParam[0] : zoneParam))

export const areaParamSuffix = '-provincia'

/**
 * Append suffix for "area" route parameter to the given string, eventually converting
 * to kebab case needed by URL segment
 *
 * @example
 * areaParamEncoder("foo") // foo-provincia
 * areaParamEncoder("Sesto San Giovanni") // sesto-san-giovanni-provincia
 *
 * @param area
 */
export const areaParamEncoder = (area: string): string => `${cityParamEncoder(area)}${areaParamSuffix}`

/**
 * To be used to extract the "city" when handing an "area" route parameter.
 * Area route parameters are in the form `<CITY>-provincia`; this method returns
 * the sub-string `<CITY>` with no additional checks, just remove the expected suffix.
 *
 * @example
 * areaParamDecoder("desio-provincia") // "desio"
 * areaParamDecoder("jj_gg-provincia") // "jj_gg"
 * areaParamDecoder(["firenze-provincia"]) // "firenze"
 *
 * @param area
 * @return null if {area} is undefined
 */
export const areaParamDecoder = (area: string | string[]): string | null => {
  if (area === undefined) {
    return null
  }

  return (Array.isArray(area) ? area[0] : area).replace(new RegExp(`${areaParamSuffix}$`), '')
}

/**
 * Returns true if the given param ends with expected suffix for "area" route parameter.
 *
 * @example
 * isAreaParam("sevilla-provincia") // true
 * isAreaParam(["genova-provincia"]) // true
 * isAreaParam("provincia-genova") // false
 * isAreaParam("bologna") // false
 *
 * @param param
 */
export const isAreaParam = (param: string | string[]): boolean =>
  param !== undefined && (Array.isArray(param) ? param[0] : param).endsWith(areaParamSuffix)

const citiesMapperUtils = {
  cityParamDecoder,
  findCity,
  findZone,
}

export default citiesMapperUtils
