import { useCallback, useEffect } from 'react'

const isPageVisible = () => {
  if (typeof document !== 'undefined' && typeof document.visibilityState !== 'undefined') {
    return document.visibilityState !== 'hidden'
  }
  // always assume it's visible
  return true
}

export const usePageFocus = (handleFocus) => {
  const focusHandler = useCallback(() => {
    if (isPageVisible() && handleFocus) {
      handleFocus()
    }
  }, [handleFocus])

  useEffect(() => {
    window.addEventListener('visibilitychange', focusHandler, false)
    return function removeFocusHandlers() {
      window.removeEventListener('visibilitychange', focusHandler, false)
    }
  }, [focusHandler])
}
