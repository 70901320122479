import { SvgProps } from '..'
import BaseSvg from '../BaseSvg'

const Delete: React.FC<SvgProps> = (props) => {
  return (
    <BaseSvg height="20" viewBox="0 0 18 20" width="18" {...props}>
      <g clipPath="url(#clip0_3789_32922)">
        <path
          d="M8.66927 1.90625V3.92625C10.9293 4.25292 12.6693 6.18625 12.6693 8.53958C12.6693 9.13958 12.5493 9.70625 12.3493 10.2329L14.0826 11.2529C14.4559 10.4262 14.6693 9.50625 14.6693 8.53958C14.6693 5.08625 12.0359 2.23958 8.66927 1.90625ZM8.0026 13.2063C5.4226 13.2063 3.33594 11.1196 3.33594 8.53958C3.33594 6.18625 5.07594 4.25292 7.33594 3.92625V1.90625C3.9626 2.23958 1.33594 5.07958 1.33594 8.53958C1.33594 12.2196 4.31594 15.2063 7.99594 15.2063C10.2026 15.2063 12.1559 14.1329 13.3693 12.4796L11.6359 11.4596C10.7826 12.5262 9.47594 13.2063 8.0026 13.2063Z"
          fill="#FF991F"
        />
      </g>
      <defs>
        <clipPath id="clip0_3789_32922">
          <rect fill="white" height="16" transform="translate(0 0.539062)" width="16" />
        </clipPath>
      </defs>
    </BaseSvg>
  )
}

export default Delete
