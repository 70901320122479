import { SvgProps } from '..'
import BaseSvg from '../BaseSvg'

const PhoneSolid: React.FC<SvgProps> = (props) => {
  return (
    <BaseSvg {...props}>
      <path
        clipRule="evenodd"
        d="M3.24967 0.333313C4.86051 0.333313 6.16634 1.61267 6.16634 3.19084C6.16634 4.38553 4.96913 5.0577 4.02832 5.57974C4.76711 7.67691 6.32925 9.35113 8.29441 10.1901L8.51622 9.77559C9.04308 8.80239 9.65723 7.83331 10.8229 7.83331C12.3933 7.83331 13.6663 9.13915 13.6663 10.75C13.6663 12.2534 12.5574 13.4912 11.1327 13.6495L10.9613 13.6625L10.9618 13.6666L10.7791 13.6662L10.4349 13.6548C4.85127 13.4035 0.451753 9.16997 0.333008 3.49606L0.349319 3.43792C0.3386 3.35498 0.333008 3.27249 0.333008 3.19084C0.333008 1.61267 1.63884 0.333313 3.24967 0.333313Z"
        fill="#1D1D1B"
        fillRule="evenodd"
      />
    </BaseSvg>
  )
}

export default PhoneSolid
