import BaseSvg, { Props } from '../BaseSvg'

const Info: React.FC<Props> = (props) => (
  <BaseSvg className={props.className} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      clipRule="evenodd"
      d="M9 0a9 9 0 1 1 0 18A9 9 0 0 1 9 0Zm0 2.25a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5Zm1.125 6.188v5.624h-2.25V8.439h2.25ZM9 3.938a1.688 1.688 0 1 1 0 3.375 1.688 1.688 0 0 1 0-3.375Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </BaseSvg>
)

export default Info
