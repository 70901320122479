import { TENANTS, TENANT_DOMAIN_ENVIRONMENT_MAPPING } from './constants'
import { Tenants } from './types'

export const isRunningE2ETests = () =>
  // @ts-ignore
  !!process.env.CYPRESS || (typeof window !== 'undefined' && window.Cypress !== undefined)

export const getTenant = (host?: string | null): Tenants => {
  if (typeof window !== 'undefined' && isRunningE2ETests()) {
    return (window as any).testForTenant ?? TENANTS.casavo
  }

  if (!host && typeof window !== 'undefined') {
    host = window.location.host
  }

  if (!host && typeof window === 'undefined') {
    throw new Error('Host unavailable on the server')
  }

  if (host === null || host === undefined) {
    throw new Error('Cannot get tenant with nullish host')
  }

  const [domain, _port] = host.split(':')

  const isUcscDomain = domain
    .split('.')
    .some(
      (subdomain) =>
        subdomain.includes(TENANT_DOMAIN_ENVIRONMENT_MAPPING.unicreditProduction) ||
        subdomain.includes(TENANT_DOMAIN_ENVIRONMENT_MAPPING.unicreditStaging)
    )

  if (isUcscDomain) return TENANTS.ucsc

  return TENANTS.casavo
}

const getTenantWithFallback =
  (fallbackTenant: Tenants) =>
  (host?: string | null): Tenants => {
    try {
      return getTenant(host)
    } catch (ex) {
      return fallbackTenant
    }
  }

export const getTenantWithCasavoFallback = getTenantWithFallback(TENANTS.casavo)
export const getTenantWithUnicreditFallback = getTenantWithFallback(TENANTS.ucsc)
