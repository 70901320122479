import Arrow from './Arrow'
import ArrowUp from './ArrowUp'
import Bathroom from './Bathroom'
import Bedroom from './Bedroom'
import Calendar from './Calendar'
import CasavoLogoSmall from './CasavoLogoSmall'
import Check from './Check'
import Chevron from './Chevron'
import Clock from './Clock'
import Close from './Close'
import DataUsage from './DataUsage'
import Delete from './Delete'
import Favourites from './Favourites'
import FavouritesRemoved from './FavouritesRemoved'
import Filter from './Filter'
import Gallery from './Gallery'
import House from './Home'
import HouseSmall from './HouseSmall'
import Info from './Info'
import InfoSolid from './InfoSolid'
import Lock from './Lock'
import Minus from './Minus'
import MyLocation from './MyLocation'
import NotificationsOff from './NotificationsOff'
import Phone from './Phone'
import PhoneSolid from './PhoneSolid'
import Plus from './Plus'
import Reply from './Reply'
import Rooms from './Rooms'
import Star from './Star'
import Sun from './Sun'
import Tap from './Tap'
import ThumbDownAlt from './ThumbDownAlt'
import Tree from './Tree'
import Verified from './Verified'
import VirtualTour from './VirtualTour'
import Warning from './Warning'

export type SvgProps = React.SVGProps<SVGSVGElement>

const ALL_ICONS = [
  'Arrow',
  'ArrowUp',
  'Bathroom',
  'Bedroom',
  'Bedrooms',
  'Close',
  'Chevron',
  'NotificationsOff',
  'Rooms',
  'Tree',
  'Sun',
  'VirtualTour',
  'Favourites',
  'FavouritesRemoved',
  'Delete',
  'Info',
  'InfoSolid',
  'Tap',
  'Phone',
  'Calendar',
  'Clock',
  'Reply',
  'Filter',
  'Check',
  'Gallery',
  'CasavoLogoSmall',
  'MyLocation',
  'HouseSmall',
  'PhoneSolid',
  'DataUsage',
  'Warning',
  'Plus',
  'Minus',
  'Star',
  'ThumbDownAlt',
  'Lock',
  'House',
  'Verified',
] as const

export type IconKey = typeof ALL_ICONS[number]

type IconProps = {
  [x in IconKey]: React.FC<SvgProps>
}

const Icon: IconProps = {
  Arrow,
  ArrowUp,
  Bathroom,
  Bedroom,
  Bedrooms: Bedroom,
  Calendar,
  CasavoLogoSmall,
  Check,
  Chevron,
  Clock,
  Close,
  DataUsage,
  Delete,
  Favourites,
  FavouritesRemoved,
  Filter,
  Gallery,
  House,
  HouseSmall,
  Info,
  InfoSolid,
  Lock,
  Minus,
  MyLocation,
  NotificationsOff,
  Phone,
  PhoneSolid,
  Plus,
  Reply,
  Rooms,
  Star,
  Sun,
  Tap,
  ThumbDownAlt,
  Tree,
  Verified,
  VirtualTour,
  Warning,
}

export default Icon
