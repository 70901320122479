import BaseSvg, { Props } from '../BaseSvg'

const Calendar: React.FC<Props> = (props) => (
  <BaseSvg className={props.className} {...props}>
    <path
      clipRule="evenodd"
      d="M6 0v1h4V0h2v1h2a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h2V0h2Zm8 6H2v7h12V6Zm-6 4a1 1 0 1 1 0 2 1 1 0 0 1 0-2Zm-3 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2Zm6-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2ZM8 7a1 1 0 1 1 0 2 1 1 0 0 1 0-2ZM5 7a1 1 0 1 1 0 2 1 1 0 0 1 0-2Zm9-4H2v1h12V3Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </BaseSvg>
)

export default Calendar
