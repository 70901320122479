import BaseSvg, { Props } from '../BaseSvg'

const CasavoLogoSmall: React.FC<Props> = (props) => (
  <BaseSvg height="16" viewBox="0 0 14 16" width="14" {...props}>
    <path
      d="M0.347251 10.2703C1.26314 13.5306 4.24007 15.9239 7.47717 15.9953C8.44909 16.0297 9.48098 15.8701 10.4447 15.5044C10.5456 15.4662 10.535 15.314 10.4302 15.2902C7.71493 14.6778 5.38099 12.575 4.79796 9.70483C4.26218 7.06696 5.03572 4.3693 6.3835 2.12C6.6046 1.75116 6.82993 1.39454 7.08768 1.04513C7.1843 0.91425 7.24972 0.79402 7.42114 0.833783C7.58227 0.871042 7.59863 1.02133 7.65163 1.26116C8.19407 3.71211 10.475 5.55344 13.001 5.62671C13.087 5.62921 13.1575 5.55626 13.1575 5.46703V0.196939C13.1575 0.088294 13.0724 0 12.967 0H7.7122V0.000939298C4.54204 0.0331885 1.82739 2.03076 0.652849 4.87151C0.652849 4.87151 -0.584083 7.2924 0.347251 10.2703Z"
      fill="currentColor"
    />
  </BaseSvg>
)

export default CasavoLogoSmall
