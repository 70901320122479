import { SvgProps } from '..'
import BaseSvg from '../BaseSvg'

import { directionRotate } from './styles.css'

type ArrowProps = SvgProps & { direction?: keyof typeof directionRotate }

const Arrow: React.FC<ArrowProps> = ({ direction = 'left', ...props }) => {
  return (
    <BaseSvg className={directionRotate[direction]} {...props}>
      <path
        clipRule="evenodd"
        d="M7.75577 1.33337L1.33317 8.00004L7.75577 14.6667L8.93428 13.4434L4.52502 8.86495L14.6665 8.86504V7.13504L4.52502 7.13495L8.93428 2.55667L7.75577 1.33337Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </BaseSvg>
  )
}

export default Arrow
