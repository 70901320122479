export enum LogoutActionType {
  PREVIUOS_PAGE = 'PREVIUOS_PAGE',
  RELOAD_PAGE = 'RELOAD_PAGE',
  SAME_PAGE = 'SAME_PAGE',
}

interface ReloadPage {
  type: LogoutActionType.RELOAD_PAGE
}

interface SamePage {
  type: LogoutActionType.SAME_PAGE
}

interface PreviousPage {
  previousPage: string
  type: LogoutActionType.PREVIUOS_PAGE
}

export type OnLogoutAction = ReloadPage | SamePage | PreviousPage

export const getLogoutAction = (type: LogoutActionType, previousPage: string): OnLogoutAction => {
  if (type === LogoutActionType.PREVIUOS_PAGE)
    return {
      previousPage,
      type,
    }
  return { type }
}

export const logoutOnPreviousPage = (previousPage: string) => {
  window.location.href = previousPage
}
