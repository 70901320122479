import BaseSvg, { Props } from '../BaseSvg'

const VirtualTour: React.FC<Props> = (props) => (
  <BaseSvg {...props}>
    <path
      clipRule="evenodd"
      d="M14.375 3.02197V13.9785L12.9531 13.725C9.92491 13.1852 6.8251 13.1852 3.79689 13.725L2.375 13.9785V3.02197L3.79689 3.27544C6.8251 3.81525 9.92491 3.81525 12.9531 3.27544L14.375 3.02197ZM13.3315 4.26762L13.1362 4.30272C10.0769 4.84808 6.94725 4.86367 3.88388 4.34947L3.61376 4.30272L3.41848 4.26788V12.7323L3.61376 12.6977C6.67312 12.1524 9.80275 12.1368 12.8661 12.651L13.1362 12.6977L13.3315 12.7326V4.26762ZM6.94023 6.41325V8.76108L6.15745 7.97805C5.74511 8.21692 5.50545 8.5036 5.50545 8.76108C5.50545 9.33957 6.71521 10.0654 8.37501 10.0654C10.0348 10.0654 11.2446 9.33957 11.2446 8.76108C11.2446 8.18259 10.0348 7.45673 8.37501 7.45673C8.19552 7.45673 8.02129 7.46522 7.85327 7.4811L7.85338 6.43393C8.02403 6.4203 8.19815 6.41325 8.37501 6.41325C10.5361 6.41325 12.2881 7.46441 12.2881 8.76108C12.2881 10.0578 10.5361 11.1089 8.37501 11.1089C6.2139 11.1089 4.46197 10.0578 4.46197 8.76108C4.46197 8.176 4.81866 7.6409 5.4087 7.2298L4.5924 6.41325H6.94023Z"
      fillRule="evenodd"
    />
  </BaseSvg>
)

export default VirtualTour
