import { newTracker, trackSelfDescribingEvent } from '@snowplow/browser-tracker'

import { TENANTS } from '@/lib/tenants/constants'
import { getTenant } from '@/lib/tenants/getTenant'

import { SnowplowEvent } from './common'

const snowplowEndpointUrl = process.env.NEXT_PUBLIC_SNOWPLOW_URL ?? 'https://mini.analytics.data.int.casavo.tech'

export const initSnowplowClientSideTracker = () => {
  const tenant = getTenant()
  if (tenant !== TENANTS.casavo) {
    return
  }
  if (!!snowplowEndpointUrl) {
    newTracker('lisa', snowplowEndpointUrl, {
      appId: 'lisa',
      cookieSameSite: 'Lax',
      discoverRootDomain: true,
      plugins: [],
    })
  }
}

export const sendSnowplowClientSideEvent = ({ data, schema }: SnowplowEvent): void => {
  const tenant = getTenant()
  if (tenant !== TENANTS.casavo) {
    return
  }

  trackSelfDescribingEvent({
    event: {
      data,
      schema,
    },
  })
}
