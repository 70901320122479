import { Description } from '@casavo/base-ui'
import useTranslation from 'next-translate/useTranslation'

import { priorityBadge, priorityBadgeText } from './style.css'

export const PriorityBadge: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className={priorityBadge}>
      <Description className={priorityBadgeText}>{t('suggested:PriorityCard_Banner')}</Description>
    </div>
  )
}
