import { SvgProps } from '..'
import BaseSvg from '../BaseSvg'

const House: React.FC<SvgProps> = (props) => {
  return (
    <BaseSvg className={props.className} {...props}>
      <g clip-path="url(#clip0_1756_4404)">
        <path
          d="M12.6668 6.2V2.66667H10.6668V4.4L8.00016 2L1.3335 8H3.3335V13.3333H7.3335V9.33333H8.66683V13.3333H12.6668V8H14.6668L12.6668 6.2ZM11.3335 12H10.0002V8H6.00016V12H4.66683V6.79333L8.00016 3.79333L11.3335 6.79333V12Z"
          fill="#1D1D1B"
        />
        <path
          d="M6.66699 6.66665H9.33366C9.33366 5.93331 8.73366 5.33331 8.00033 5.33331C7.26699 5.33331 6.66699 5.93331 6.66699 6.66665Z"
          fill="#1D1D1B"
        />
      </g>
      <defs>
        <clipPath id="clip0_1756_4404">
          <rect fill="white" height="16" width="16" />
        </clipPath>
      </defs>
    </BaseSvg>
  )
}

export default House
