/**
 * HACK
 * WHAT: avoid modal scroll on safari mobile without losing initial scrollY position on modal close
 * https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
 */

/**
 * update 2022-11-25
 * disabling checks about device === 'mobile' due to inconsistent behaviour when opening a modal on page load.
 * e.g. when ?dealId is present in url query (after click on hubspot email cta) for opening the schedule visit modal
 * for future reference: the main issue is that since there was no user interaction before opening the modal,
 * the DeviceContext has the 'device' set to mobile (since it's the default) and hasn't updated its value,
 * so it causes layout and usability issues
 * https://casavo.atlassian.net/browse/BUY-2484
 */

import { MODAL_ANIMATION_TIMING } from '@/components/framer-motion-variants'

export const addPositionFixedToBody = (scrollbarWidth: number) => {
  document.body.style.top = `-${window.scrollY}px`
  document.body.style.position = 'fixed'
  document.body.style.right = '0'
  document.body.style.left = '0'
  document.body.style.paddingRight = `${scrollbarWidth}px`
}

const removePositionFixedFromBody = () => {
  document.body.style.top = ''
  document.body.style.position = ''
  document.body.style.right = ''
  document.body.style.left = ''
  document.body.style.paddingRight = '0'
}

export const backToTopOnModalClose = () => {
  setTimeout(() => {
    removePositionFixedFromBody()
  }, MODAL_ANIMATION_TIMING)
}

export const backToInitialScrollOnModalClose = () => {
  const initialScroll = document.body.style.top
  setTimeout(() => {
    removePositionFixedFromBody()
    window.scrollTo(0, parseInt(initialScroll || '0') * -1)
  }, MODAL_ANIMATION_TIMING)
}
