import { SvgProps } from '..'
import BaseSvg from '../BaseSvg'

const Tap: React.FC<SvgProps> = (props) => {
  return (
    <BaseSvg className={props.className} {...props}>
      <path
        clipRule="evenodd"
        d="M6.005 4a1 1 0 0 1 1 1v2.916l.002-.033A1 1 0 0 1 9 8a1 1 0 0 1 2 0v1a1 1 0 1 1 2 0v2.824l.005.176v1a3 3 0 0 1-3 3h-2c-1.32 0-2.318-.838-2.505-1.025l-2.828-2.829a1.5 1.5 0 1 1 2.121-2.12l.211.211L5.005 5a1 1 0 0 1 1-1Zm5.245-2.665a1 1 0 0 1 .366 1.366l-1 1.732a1 1 0 0 1-1.732-1l1-1.732a1 1 0 0 1 1.366-.366ZM2.116 1.7l1 1.732a1 1 0 1 1-1.732 1l-1-1.732a1 1 0 1 1 1.732-1ZM6 0a1 1 0 0 1 1 1v1a1 1 0 0 1-2 0V1a1 1 0 0 1 1-1Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </BaseSvg>
  )
}

export default Tap
