import React, { createContext, useContext, useEffect, useState } from 'react'

import apiGlugluPrivate from '@/lib/api-gluglu-auth'

import { useAuthentication } from './AuthProvider'

export type Props = {
  disableSuggestedCriteria: VoidFunction
  enableSuggestedCriteria: VoidFunction
  hasSuggestedCriteria: boolean
}

const defaultContextValue: Props = {
  disableSuggestedCriteria: () => {},
  enableSuggestedCriteria: () => {},
  hasSuggestedCriteria: false,
}

export const SuggestedCriteriaContext = createContext<Props>(defaultContextValue)

export const useSuggestedCriteria = () => {
  const context = useContext(SuggestedCriteriaContext)
  if (!context) {
    throw new Error(`useSuggestedCriteria must be used within a SuggestedCriteriaContext`)
  }
  return context
}

export const SuggestedCriteriaProvider: React.FC = ({ children }) => {
  const { user } = useAuthentication()
  const [hasSuggestedCriteria, setHasSuggestedCriteria] = useState<boolean>(false)

  const fetchCriteriaInfo = async () => {
    try {
      const res = await apiGlugluPrivate.getSuggestionCriteria()
      setHasSuggestedCriteria(!!res)
    } catch (error) {
      console.error('Error fetching suggestion criteria:', error)
      // show an error message to the user
    }
  }

  useEffect(() => {
    user?.id && fetchCriteriaInfo()
  }, [user?.id])

  const enableSuggestedCriteria = () => {
    setHasSuggestedCriteria(true)
  }

  const disableSuggestedCriteria = () => {
    setHasSuggestedCriteria(false)
  }

  return (
    <SuggestedCriteriaContext.Provider
      value={{
        disableSuggestedCriteria,
        enableSuggestedCriteria,
        hasSuggestedCriteria,
      }}
    >
      {children}
    </SuggestedCriteriaContext.Provider>
  )
}
