
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { NextComponentType } from 'next'
import { AppContext, AppInitialProps, AppProps } from 'next/app'
import { ErrorProps } from 'next/error'
import Head from 'next/head'
import { useEffect, useRef } from 'react'
import 'react-image-gallery/styles/css/image-gallery.css'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Hydrate } from 'react-query/hydration'
import smoothscroll from 'smoothscroll-polyfill'

// https://github.com/aralroca/next-translate/issues/508
import 'intl-pluralrules'

import '../index.css'
import '@casavo/base-ui/style.css'
import '@casavo/habitat/style.css'

import { AuthProvider } from '@/context/AuthProvider'
import { BottomSheetProvider } from '@/context/BottomSheetContext'
import { DeviceProvider } from '@/context/Device'
import { FavoritesProvider } from '@/context/FavoritesProvider'
import { KeydownContextProvider } from '@/context/KeydownContext'
import { ModalContextProvider } from '@/context/ModalContext'
import { QueryParamProvider } from '@/context/QueryParam'
import { SuggestedCriteriaProvider } from '@/context/SuggestedCriteriaProvider'
import { UserNavigationProvider } from '@/context/UserNavigationProvider'
import { getAppRevision, getAppVersion } from '@/utils/environment'
import { PageType } from '@/utils/get-page-type-from-params'
import { isStorageSupported } from '@/utils/isStorageSupported'
import { RecaptchaScript } from '@/utils/third-party/recaptcha/script'
import { initSnowplowClientSideTracker } from 'lib/tracking/snowplow/client'

if (typeof window !== 'undefined') {
  smoothscroll.polyfill()
}

if (typeof window !== 'undefined') {
  window.appVersion = getAppVersion()
  window.appRevision = getAppRevision()
}

type CustomAppComponent = NextComponentType<
  AppContext,
  AppInitialProps,
  AppProps<{ dehydratedState: unknown; pageType: PageType }> & { err: ErrorProps }
>

const MyApp: CustomAppComponent = ({ Component, err, pageProps }) => {
  useEffect(() => {
    if (isStorageSupported(() => sessionStorage)) {
      sessionStorage.removeItem('CASAVO@LISTING-BACK')
      sessionStorage.removeItem('CASAVO@LISTINGS-PAGE-OFFSET')
      sessionStorage.removeItem('CASAVO@LISTINGS-MAP-COORDINATES')
    }
  }, [])

  const queryClient = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnReconnect: false,
          refetchOnWindowFocus: false,
        },
      },
    })
  )

  useEffect(() => {
    initSnowplowClientSideTracker()
  }, [])

  return (
    <>
      <Head>
        <meta content="width=device-width, initial-scale=1, shrink-to-fit=no" name="viewport" />
        <meta content="index, follow" name="robots" />
      </Head>

      <RecaptchaScript />
      <div id="app">
        <QueryClientProvider client={queryClient.current}>
          <Hydrate state={pageProps.dehydratedState}>
            <UserNavigationProvider pageType={pageProps.pageType}>
              <DeviceProvider>
                <QueryParamProvider>
                  <KeydownContextProvider>
                    <AuthProvider>
                      <SuggestedCriteriaProvider>
                        <FavoritesProvider>
                          <ModalContextProvider>
                            <BottomSheetProvider>
                              <Component {...pageProps} err={err} />
                            </BottomSheetProvider>
                          </ModalContextProvider>
                        </FavoritesProvider>
                      </SuggestedCriteriaProvider>
                    </AuthProvider>
                  </KeydownContextProvider>
                </QueryParamProvider>
              </DeviceProvider>
            </UserNavigationProvider>
          </Hydrate>
        </QueryClientProvider>
      </div>
    </>
  )
}
const __Page_Next_Translate__ = MyApp


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  